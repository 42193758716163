import React, { Component } from 'react'
import api from '../../services/api'
import If from '../common/IF'

export default class PerguntasVisita extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            showOptions: false,
            perguntas: [],
            respostas: [],
            soRespostas: []
        }
    }

    componentDidMount() {
        this.getPerguntas()
    }

    async getPerguntas() {
        const url = '/imoveis/listaPerguntas'

        api.get(url).then((response) => {
            console.log("res", response.data)
            this.setState({ perguntas: response.data.perguntas })

            let arrayRespostas = []
            let respostasNull = []

            response.data.perguntas.map((pergunta) => {
                respostasNull.push('nao')
                arrayRespostas =
                    [
                        ...arrayRespostas,
                        {
                            idPergunta: pergunta.id,
                            resposta: 'nao',
                            idImovel: this.props.idImovel,
                            idVisita: this.props.idVisita
                        }
                    ]
            })

            console.log('respostas: ', arrayRespostas)

            this.setState({
                soRespostas: respostasNull,
                respostas: arrayRespostas,
                showModal: true,
                showOptions: true,
            })
        }).catch((err) => console.log('err', err))
    }

    close() {
        this.props.close()
    }

    adicionarRespostas = (resposta, index) => {
        let novasRespostas = this.state.respostas

        novasRespostas[index].resposta = resposta === true ? 'sim' : 'nao'

        this.setState({ respostas: novasRespostas })
    }

    async enviarRespostas() {
        const url = '/imoveis/respostaPerguntas'

        api.post(url, { respostas: this.state.respostas })
            .then((response) => {
                console.log('enviado', response)
            })
            .catch((err) => console.log('erro resposta', err))
            .finally(() => {
                this.props.finalizarAtendimento(this.props.idVisita)
                this.close()
            })
    }

    render() {
        return (
            <If test={this.state.showModal === true}>
                <div
                    zIndex={200000000002}
                    key={Math.random()}
                    className="modal animated fadeIn faster"
                >
                    <div className="modal-background" />
                    <div className="modal-container">
                        <div className="modal-card">
                            <header className="modal-card-head">
                                <p className="modal-card-title" >Questionário</p>
                            </header>
                            <If test={this.state.showOptions === true}>
                                {(this.state.respostas.length > 0) && (this.state.respostas.map((resposta, index) => (
                                    <section className="modal-card-body">
                                        <strong>{this.state.perguntas[index].descricao}</strong>
                                        <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                            <label className="checkbox" style={{ marginRight: 20 }}>
                                                <input
                                                    style={{ marginRight: 5 }}
                                                    type='checkbox'
                                                    name={`sim${index}`}
                                                    value='sim'
                                                    checked={this.state.respostas[index].resposta === 'sim' ? true : false}
                                                    onClick={(event) => this.adicionarRespostas(true, index)}
                                                />
                                                Sim
                                        </label>
                                            <label className="checkbox">
                                                <input
                                                    style={{ marginRight: 5 }}
                                                    type='checkbox'
                                                    name={`nao${index}`}
                                                    value='nao'
                                                    checked={this.state.respostas[index].resposta === 'nao' ? true : false}
                                                    onClick={(event) => this.adicionarRespostas(false, index)}
                                                />
                                                Não
                                    </label>
                                        </div>
                                    </section>
                                )))}
                            </If>
                            <button
                                onClick={() => this.enviarRespostas()}
                                className="button is-success"
                            >
                                Responder
                        </button>
                        </div>
                    </div>
                </div>
            </If>
        )
    }
}
