import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import {Link} from "react-router-dom"


class DetalhesVistoria extends Component {

    state = {
        hamburguer: true,
        vistoria: "",
        error: false,
        message: ""
    }

    componentWillMount = async() => {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        let resposta = await this.detalhesVistorias2()
        this.setState({vistoria:resposta.data.vistoria}) 
    }

    detalhesVistorias2 = async () => {
        let id = this.props.match.params.id;
        let response = await api.get('/vistorias/detalhes/' + id)
        return response;
    }

    render() {
        return (
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    <div className="card">
                        {this.state.vistoria !== "" &&
                        <div className="card-content">
                                <h4 className="card-title">Vistoria: {this.props.match.params.id}</h4>
                                <h5>Endereço do Imóvel: </h5>
                                <p>{this.state.vistoria.imovel.endereco}</p> 
                                <h5>Locatário: </h5>
                                <p>Nome: {this.state.vistoria.locatario.nome}</p>
                                <p>Email: {this.state.vistoria.locatario.email}</p>
                                <p>Telefones:</p>
                                {this.state.vistoria.locatario.telefones.map((item,index)=>(
                                    item !== null &&(
                                        <p>{item}</p>
                                    )
                                ))}
                                <h5>Proprietários:</h5>
                                {this.state.vistoria.proprietarios.length > 0 && this.state.vistoria.proprietarios.map((item,index)=>(
                                    <React.Fragment>
                                    <p>Nome: {item.nome}</p>
                                    <p>E-mail: {item.email}</p>
                                    <p>Telefones:</p>
                                    {item.telefones.length > 0 && item.telefones.map((itemfone,index) => (
                                        itemfone !== null &&(
                                            <p>{itemfone}</p>
                                        )
                                    ))}
                                    </React.Fragment>
                                    
                            
                                )) }
                                <h5>Consultor:</h5>
                                <p>Nome: {this.state.vistoria.consultor.nome}</p>

                        </div>
                        }
                    </div>
                    <Link style={{marginTop:10,marginBottom:10}} to={"/vistorias"} className="button is-small">Voltar</Link>
                </div>
            </div>
        )
    }
}

export default DetalhesVistoria