import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import history from "../routes/history"
import CustomSnackBar from "../components/material/SnackBar"
import {Link} from "react-router-dom"
import If from '../components/common/IF'


class FotosLaudo extends Component {

    constructor(props) {

        super(props);

        this.state = {
            hamburguer: true,
            "idVistoria": this.props.match.params.id,
            "descricao": "",
            "multipleFiles":null,
            "fotos": [],
            "error":false,
            "message":"",
            "imagem":"",
            "enviando":"nao"
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event, index) {


        if(event.target.name === "descricao"){
            this.setState({ "descricao": event.target.value });
        }

        if(event.target.name === "multipleFiles[]"){

           
            this.setState({"imagem":event.target.files[0].name});
            this.setState({"multipleFiles":event.target.files[0] });
        }

       
    }

    handleSubmit(event) {
        event.preventDefault();


        console.log(this.state);

        if(this.state.descricao === ""){
            this.setState({"error":true});
            this.setState({"message":"Digite uma descrição"});

            setTimeout(() => {
                this.setState({
                    error: false,
                })
                this.setState({
                    message: '',
                })
            }, 3000);

            return false;
        }


        if(this.state.multipleFiles == null){
            this.setState({"error":true});
            this.setState({"message":"Por favor anexo uma foto"});

            setTimeout(() => {
                this.setState({
                    error: false,
                })
                this.setState({
                    message: '',
                })
            }, 3000);

            return false;
        }


        this.setState({
            enviando: "sim"
        })


        const formData = new FormData();
        formData.append('multipleFiles', this.state.multipleFiles);
        formData.append('descricao',this.state.descricao);
        formData.append('id',this.state.idVistoria);


        api.post("/vistorias/enviarfotos", formData)
        .then(response => {
            console.log(response.data)
            history.push("/");
            history.push("/fotoslaudo/"+this.state.idVistoria)
        })
        .catch(error => console.log(error.response))

    }

    buscaFotos = () => {


        api.get("/vistorias/fotosLaudo/"+this.props.match.params.id)
        .then(response => {
            this.setState({ "fotos": response.data.fotos });
        })
        .catch(error => console.log(error.response))


    }



    componentWillMount = () => {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        this.buscaFotos();
    }


    render() {
        return (
           
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>


                {this.state.error === true &&
                    <CustomSnackBar variant="error" message={this.state.message}/>
                }

                

                    <div className="card">
                        <div className="card-content">
                            <form id="novoAmbiente">
                                <h4 className="card-title">Fotos Vistorias</h4>
                                <div className="field">
                                    <label className="label">Descricao</label>
                                    <div className="field is-grouped">
                                        <p className="control is-expanded">
                                            <input class="input" required name="descricao" onChange={(e) => this.handleChange(e)} placeholder="Descrição do ambiente" type="text" value={this.state.descricao} ></input>
                                        </p>
                                    </div>
                                </div>


                            


                                <div className="field">
                                    <div className="file is-primary">
                                        <label className="file-label">
                                            <input className="file-input" required type="file" onChange={(e) => this.handleChange(e)} name="multipleFiles[]"></input>
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    Selecione uma imagem
                                                    </span>
                                            </span>
                                            <If test={this.state.imagem !== ""}>
                                            <span class="file-name">
                                                {this.state.imagem}
                                            </span>
                                            </If>
                                        </label>
                                    </div>
                                </div>

                                <div className="field is-grouped">
                                    <div className="control">
                                    {this.state.enviando === "sim" ?
                                         <button className="button" onClick={(e) => this.handleSubmit(e)}>Aguarde Enviando ...</button>
                                        :
                                        <button className="button is-link" onClick={(e) => this.handleSubmit(e)}>Enviar Imagem</button>
                                    }
                                    </div>
                                </div>
                            </form>

                            
                            </div>

                            

        
                            </div>



                   


                    <div className="card " style={{marginTop:20,marginBottom:10}}>

                
                        <div className="card-content" >

                        <h4 class="card-title">Fotos Adicionadas:</h4>

                         



                            <div className="columns  is-multiline">

                             
                                    {this.state.fotos.length > 0 ?
                                        this.state.fotos.map((item,index)=> (
                                            <div key={index} className=" column is-3" style={{marginTop:10, background:'transparent'}}>
                                             
                                                    <article className=" card column" style={{background:'#fff'}}>
                                                        <figure className="image is-3by2">
                                                            <img src={item.link} alt='img'></img>
                                                        </figure>
                                                        <p className="has-text-centered">{item.descricao}</p>
                                                    </article>
                                               
                                            </div>
                                        )):
                                        <p className="has-text-danger has-text-centered">Nenhuma foto adicionada ate o momento</p>
                                    }

                              
                             



                            </div>
                            

                          

                        </div>

                        

                        </div>

                        <Link style={{marginTop:10,marginBottom:10}} to={"/laudo/"+this.props.match.params.id} className="button is-small">Voltar</Link>
                        </div>

                        </div>
               
                )
            }
        }
        
export default FotosLaudo