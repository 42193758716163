import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';

/*if('serviceWorker' in navigator){
  navigator.serviceWorker.register('./firebase-messaging-sw.js').then((registration)=>{
    console.log('registro ok', registration.scope)
  }).catch((err)=>console.log('erro no registro~> ', err))
}*/

// testando o registro do pushmanager
/*if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./firebase-messaging-sw.js').then((registration) => {
    registration.pushManager.subscribe().then(function(pushSub){
      console.log('registro ok', pushSub)
    }, function(err) { console.log('registro error', err) })
  }).catch((err) => console.log('erro no registro~> ', err))
}*/


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
