import React from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import "../styles/login.css"

import CustomSnackBar from "../components/material/SnackBar"
import Input from "../components/forms/Input"

import api from "../services/api"
import InputMask from "react-input-mask"
import logo from "../images/logo_color.png"

import history from "../routes/history"

class RecuperarSenha extends React.Component {
    state = {
        erroMsg: false,
        errorPassword: false,
        resetOk: false,
        passwordMessage: false,
        forgotPassword: false,
        receiveCode: false,
        documento: "",
        senha: "",
        fcmtoken: "",
        codigo: "",
        tipo: "2",
        notification: false,
        naoAutorizado: false
    }

    resetPassword = () => {
        const documento = this.state.documento
        const doc2 = documento
            .replace(".", "")
            .replace("-", "")
            .replace(".", "")
        api.post("/clientes/recuperarSenha", {
            documento: doc2,
            codigo: this.state.codigo,
            senha: parseInt(this.state.senha)
        })
            .then(response => {
                console.log(response.data)
                setTimeout(() => {
                    history.push("/consultor/login")
                }, 3000)
                this.setState({
                    resetOk: true
                })
            })
            .catch(error => console.log(error.response))
    }

    recuperarSenha = () => {
        const documento = this.state.documento
        const doc2 = documento
            .replace(".", "")
            .replace("-", "")
            .replace(".", "")
        api.post("/clientes/solicitarRecuperacaoSenha", {
            documento: doc2
        })
            .then(response => {
                this.setState({
                    passwordMessage: true,
                    forgotPassword: false,
                    receiveCode: true
                })
            })
            .catch(error => console.log(error.response))
    }

    render() {
        return (
            <div className="content-login">
                <div className="card container-login">
                    <div className="card-content card-login">
                        {this.state.errorPassword && (
                            <CustomSnackBar variant="error" message={this.state.erroMsg} />
                        )}

                        {this.state.resetOk && (
                            <CustomSnackBar
                                variant="success"
                                message="Sua senha foi alterada com sucesso"
                            />
                        )}
                        {this.state.passwordMessage && (
                            <CustomSnackBar
                                variant="success"
                                message="Você ira receber um código no seu e-mail cadastrado. Insira abaixo"
                            />
                        )}
                        <img className="img-login" src={logo} alt="" />
                        <h4 className="title is-5">Recuperar Senha</h4>

                        <InputMask
                            mask="999.999.999-99"
                            value={this.state.documento}
                            onChange={e => this.setState({ documento: e.target.value })}
                        >
                            {() => (
                                <div className="field">
                                    <div className="control has-icons-left has-icons-right">
                                        <input
                                            className="input"
                                            placeholder="Informe Seu CPF"
                                            defaultValue={this.state.documento}
                                        />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-address-card"></i>
                                        </span>
                                    </div>
                                </div>
                            )}
                        </InputMask>

                        {this.state.receiveCode && (
                            <>
                                <Input
                                    type="text"
                                    placeholder="Código"
                                    icon="fas fa-comment"
                                    chave="codigo"
                                    value={this.state.codigo}
                                    onChange={(e) => this.setState({ codigo: e.target.value })}
                                />

                                <Input
                                    type="password"
                                    placeholder="Nova Senha"
                                    icon="fas fa-user"
                                    chave="senha"
                                    value={this.state.senha}
                                    onChange={(e) => this.setState({ senha: e.target.value })}
                                />
                            </>
                        )}

                        {!this.state.receiveCode ? (
                            <div>
                                <button onClick={this.recuperarSenha} className="btn-login">
                                    Enviar
                                </button>
                                <Link to="/consultor/login" style={{ color: "#3498db" }}>
                                    Voltar
                                </Link>
                            </div>
                        ) : (
                                <button onClick={this.resetPassword} className="btn-login">
                                    Resetar
                            </button>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    imovel: state.imovel
})

export default withRouter(connect(mapStateToProps)(RecuperarSenha))
