import React, { Component } from 'react'

import history from '../../routes/history'
import If from "../common/IF"

import ModalUploadMidea from '../modals/ModalUploadMidea'
import ModalUploadVideo from '../modals/ModalUploadVideo'
import ModalObersevacaoMidia from '../modals/ModalObersevacaoMidia'

class NewCardMidia extends Component {
    render() {
        let img = ''
        if (this.props.imovel.length > 0) {
            img = this.props.imovel.fotos[0].link
        } else {
            img = "https://planob.classeloc.com.br//images/semfoto.png"
        }
        return (
            <div className="column is-full">
                <div className="card">
                    <div className="card-content">
                        <div className="columns">
                            <div className="column is-3 center-info-card">
                                <img style={{ width: 250, height: 200 }} src={img} alt="" />
                            </div>
                            <div className="column row-info-card">
                                <h6 style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>Endereço</h6>
                                <p style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>{this.props.imovel.endereco_completo}</p>
                            </div>
                            <div className="column row-info-card">
                                <h6 style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>Informações do Proprietário</h6>
                                <p style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}>
                                    <strong>Nome: </strong> {this.props.imovel.proprietarios[0] ? this.props.imovel.proprietarios[0].Nome : 'Não Informado'}
                                </p>
                                <p style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}>
                                    <strong>E-mail: </strong> {this.props.imovel.proprietarios[0] ? this.props.imovel.proprietarios[0].Email : 'Não Informado'}
                                </p>
                                {(this.props.imovel.proprietarios[0].Telefones.length > 0) && (
                                    this.props.imovel.proprietarios[0].Telefones.map((telefone) => (
                                        <p style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}>
                                            <strong>Telefone: </strong>{telefone}
                                        </p>
                                    ))
                                )}
                                <div className="column content-action">
                                    <button
                                        onClick={() => { console.log('click'); this.props.detalhe(this.props.index, this.props.imovel.id) }}
                                        className="button is-info is-small"
                                        style={{ marginTop: 10, fontWeight: 'bold', width: 220 }}
                                    >
                                        Detalhes
                                    </button>
                                </div>
                            </div>
                            <div className="column is-2 row-info-card">
                                <h6 style={{ textAlign: 'center' }}>Ações</h6>
                                <If test={this.props.imovel.projetoId.status === "CANCELADO"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>PROJETO CANCELADO</p>
                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            rImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.status === "RECUSADO"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>PROJETO RECUSADO</p>
                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            listarImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.resposta === "PENDENTE" && this.props.imovel.projetoId.status === "PENDENTE"}>
                                    <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'green' }}>NOVO PROJETO</p>
                                    <div className="column content-action">
                                        <button
                                            onClick={() => this.props.atendimento('aceito', this.props.imovel.projetoId.id)}
                                            className="button is-success is-primary is-small">
                                            Aceitar
                                    </button>
                                        <button
                                            onClick={() => this.props.atendimento('recusado', this.props.imovel.projetoId.id)}
                                            className="button is-danger is-primary is-small">
                                            Recusar
                                    </button>
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.status === "PENDENTE" && this.props.imovel.projetoId.resposta === "RECUSADO"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>VOCÊ RECUSOU O PROJETO</p>
                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            listarImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.resposta === "ACEITO" && this.props.imovel.projetoId.status === "REFAZER"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#bdb85e' }}>O PROJETO PRECISA SER REFEITO</p>
                                        <button
                                            onClick={() => history.push({
                                                pathname: '/midias/listagem',
                                                state: {
                                                    fotos: this.props.imovel.fotos,
                                                    idImovel: this.props.imovel.id,
                                                    videos: this.props.imovel.videos
                                                }
                                            })}
                                            className="button is-small is-primary">
                                            Ver Mídias
                                    </button>

                                        {window.innerWidth > 640 &&
                                            <ModalUploadMidea
                                                buttonLabel={'Enviar Fotos'}
                                                idVisita={this.props.imovel}
                                                idImovel={this.props.imovel.id}
                                                listarImoveis={() => this.props.listarImoveis()}
                                                snackSuccess={this.props.snackSuccess}
                                                snackError={this.props.snackError} />
                                        }

                                        {window.innerWidth > 640 &&
                                            <ModalUploadVideo
                                                modalVideo={this.props.modalVideo}
                                                idImovel={this.props.imovel.id}
                                                idProjeto={this.props.imovel.projetoId.id}
                                                snackSuccess={this.props.snackSuccess}
                                                snackError={this.props.snackError} />
                                        }

                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            listarImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.status === "ACEITO"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'green' }}>PROJETO ACEITO</p>
                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            listarImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>
                                <If test={this.props.imovel.projetoId.status !== "CANCELADO" && this.props.imovel.projetoId.status !== "RECUSADO" && this.props.imovel.projetoId.status !== "ACEITO" && this.props.imovel.projetoId.resposta === 'ACEITO' && this.props.imovel.projetoId.status !== "REFAZER"}>
                                    <div className="column content-action">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'green' }}>ENVIE FOTOS PARA ESSE PROJETO</p>
                                        <button
                                            onClick={() => {
                                                history.push({
                                                    pathname: '/midias/listagem',
                                                    state: {
                                                        idProjeto: this.props.imovel.projetoId.id,
                                                        idImovel: this.props.imovel.id,
                                                    }
                                                })
                                            }}
                                            className="button is-small is-primary"
                                        >
                                            Ver Mídias
                                    </button>
                                        {window.innerWidth > 640 &&
                                            <ModalUploadMidea
                                                buttonLabel={'Enviar Fotos'}
                                                idVisita={this.props.imovel}
                                                idImovel={this.props.imovel.id}
                                                listarImoveis={() => this.props.listarImoveis()}
                                                snackSuccess={this.props.snackSuccess}
                                                snackError={this.props.snackError} />
                                        }
                                        {window.innerWidth > 640 &&
                                            <ModalUploadVideo
                                                modalVideo={this.props.modalVideo}
                                                idImovel={this.props.imovel.id}
                                                idProjeto={this.props.imovel.projetoId.id}
                                                snackSuccess={this.props.snackSuccess}
                                                snackError={this.props.snackError} />
                                        }
                                        <ModalObersevacaoMidia
                                            buttonLabel={'Informações'}
                                            idVisita={this.props.imovel}
                                            idImovel={this.props.imovel.id}
                                            listarImoveis={() => this.props.listarImoveis()}
                                            snackSuccess={this.props.snackSuccess}
                                            snackError={this.props.snackError} />
                                    </div>
                                </If>

                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default NewCardMidia