import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import history from "../routes/history"


class InfoAmbiente extends Component {

    constructor(props) {

        super(props);

        this.state = {
            hamburguer: true,
            "idVistoria":"",
            "idAmbiente":this.props.match.params.id,
            "info":{}
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event,index) {
        let copiaLinha = Object.assign([],this.state.info);
        copiaLinha.descricao=event.target.value;
        this.setState({"info":copiaLinha});
    }

    handleSubmit(event) {
        event.preventDefault();

        console.log(this.state);

    
        api.put("/vistorias/editarInfo", {
            "idInfo": this.state.info.id,
            "descricao": this.state.info.descricao
        })
        .then(response => {
            console.log(response.data)
            history.push("/laudo/"+this.state.idVistoria)
        })
        .catch(error => console.log(error.response))

    }

    buscaInfo = () => {

        var id = this.props.match.params.id;
        api.get('/vistorias/listarInfo/'+id)
            .then(response => {
                
                console.log(response.data)

                this.setState({
                info: response.data.info,
                idVistoria:response.data.info.idVistoria
                }

                
                
            )})
    }

  

    componentWillMount = () => {
        if(window.innerWidth <= 640){
            this.setState({
                hamburguer: false,
            })
        }

        this.buscaInfo();
       
    }


    render() {
        return (
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger}/>
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger}/>
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>


                <div className="card">
                    <div className="card-content">
                    
                        <form id="novoAmbiente">

                            <h4 className="card-title">Informações</h4>
                                <div className="field">
                                <label className="label">Descricao</label>
                                <div class="field is-grouped">
                                    <p class="control is-expanded">
                                        <input class="input" name="titulos[]"  onChange={(e) => this.handleChange(e)} placeholder="Descrição do ambiente"  type="text" value={this.state.info.descricao} ></input>
                                    </p>
                                </div>
                                </div>
                          
                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-link" onClick={(e) => this.handleSubmit(e)}>Alterar</button>
                                </div>
                            </div>

                        </form>
                                             
                    </div>
                                      
                </div>

                

                </div>
               
            </div>
        )
    }
}

export default InfoAmbiente