import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Creators } from "../store/ducks/midias"
import { Creators as AppCreators } from "../store/ducks/app"

import CustomSnackBar from "../components/material/SnackBar"
import Loading from "../components/common/Loading"
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import If from '../components/common/IF'

import NewCardMidia from "../components/cards/NewCardMidia"
import api from "../services/api"

class Midias extends React.Component {
    state = {
        open: false,
        hamburguer: true,
        imovel: [],
        snackOk: false,
        snackError: false,
        snackUpload: false,
        files: null,
        openModalVideo: false,
        sendMidiaSucess: false,
        sendMidiaError: false,
        projetoCancelado: false,
        msgError: "",
        showModalDetalhe: false,
        indexClick: -1,
        detalhe: {}
    }

    componentDidMount() {
        this.listarImoveis()

        try {
            if (this.props.location.state.msg) {
                this.setState({
                    projetoCancelado: true,
                    msgError: this.props.location.state.msg
                })
            }
            setTimeout(() => {
                this.setState({
                    projetoCancelado: false
                })
            }, 4000)
        } catch { }
    }

    componentWillMount() {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false
            })
        }
    }

    detalhesState = (index, idImovel) => {
        this.setState({ open: true })
        api.get(`/imoveis/dadosPessoasImovel/${idImovel}`).then((response) => {
            console.log('imovelInfo~> ', response)
            this.setState({
                detalhe: response.data,
                indexClick: index,
                showModalDetalhe: true,
                open: false
            })
        }).catch((err) => {
            console.log('Error', err.response)
            this.setState({ open: false })
        })
    }

    listarImoveis() {
        this.setState({
            open: true
        })
        let idConsultor = localStorage.getItem("id")

        api.get(`/consultor/listagemImoveisFotosConsultor/${idConsultor}`)
            .then(response => {
                console.log('get~> ', response.data)
                this.setState({
                    imovel: response.data.imovel,
                    open: false
                })
            })
            .catch(response => {
                console.log(response.data)
                this.setState({
                    open: false
                })
            })
    }

    atendimentoFotos(tipo, idProjeto) {
        this.setState({ open: true })
        if (tipo === "aceito") {
            api.get(`/consultor/aceitecf`, {
                params: {
                    idprojeto: idProjeto
                }
            })
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        snackOk: true,
                        open: false
                    })
                    this.listarImoveis()
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.setState({ open: false })
                })
                .finally(this.setState({ snackOk: false }))
        } else {
            api.get(`/consultor/recusacf`, {
                params: {
                    idprojeto: idProjeto
                }
            })
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        snackError: true,
                        open: false
                    })
                    this.listarImoveis()
                })
                .catch((error) => {
                    console.log(error.response.data)
                    this.setState({ open: false })
                })
                .finally(this.setState({ snackError: false }))
        }
    }

    changeListMidiaPage() {
        console.log("apertou")
        this.props.history.push("/")
    }

    verMidias = imovel => {
        this.setState({ imovel })
        this.props.modal(true)
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    sucessSend = state => {
        this.setState({
            sendMidiaSucess: state
        })
    }

    errorSend = state => {
        this.setState({
            sendMidiaSucess: state
        })
    }

    fecharModalDetalhes() {
        this.setState({ showModalDetalhe: false, indexClick: -1 })
    }

    renderData = (value, index) => (
        <section className="modal-card-body">
            <If test={index === 0}>
                <strong>Proprietário Principal:</strong>
                <p>Nome: {value.Nome}</p>
                <p>Email: {value.Email}</p>
                {(value.Telefones.map((valueTelefones) => (
                    <p>Telefone: {valueTelefones}</p>
                )))}
            </If>
            <br />
            <If test={index !== 0}>
                <p>Proprietário nº {index}</p>
                <p>Nome: {value.Nome}</p>
                <p>Email: {value.Email}</p>
                {(value.Telefones.map((valueTelefones) => (
                    <p>Telefone: {valueTelefones}</p>
                )))}
            </If>
        </section>
    )

    render() {
        const listImovel = this.state.imovel.slice(0).reverse()
        return (
            <div className="main">
                {(this.state.showModalDetalhe === true) && (
                    <div
                        zIndex={200000000002}
                        key={Math.random()}
                        className="modal animated fadeIn faster"
                    >
                        <div className="modal-background" />
                        <div className="modal-container">
                            <div className="modal-card">
                                <header className="modal-card-head">
                                    <p className="modal-card-title" >Proprietários do Imóvel: {this.state.detalhe.idImovel}</p>
                                    <button
                                        className="button fechar-modal"
                                        onClick={() => this.fecharModalDetalhes()}
                                    ><strong>fechar</strong></button>
                                </header>
                                {(((this.state.showModalDetalhe === true) && this.state.detalhe.proprietarios.length > 0)) &&
                                    (this.state.detalhe.proprietarios.map((value, index) => (
                                        this.renderData(value, index)
                                    )))
                                }
                            </div>
                        </div>
                    </div>
                )}

                <Loading open={this.state.open} />
                <Sidebar
                    hamburguer={this.state.hamburguer}
                    changeHamburger={this.changeHamburger}
                />
                <Navbar
                    title="Fotos & Vídeos"
                    hamburguer={this.state.hamburguer}
                    changeHamburger={this.changeHamburger}
                />
                <div
                    className={
                        "animated content " +
                        (this.state.hamburguer
                            ? "paddingLeftSide bounceInLeft fast"
                            : "bounceInRight fast")
                    }
                >
                    {this.state.snackOk && (
                        <CustomSnackBar
                            variant="success"
                            message="Você aeitou o trabalho fotogŕafico"
                        />
                    )}
                    {this.state.snackError && (
                        <CustomSnackBar
                            variant="error"
                            message="Você recusou o trabalho fotográfico"
                        />
                    )}
                    {this.state.sendMidiaSucess && (
                        <CustomSnackBar variant="success" message="Mídia enviada com sucesso" />
                    )}
                    {this.state.sendMidiaError && (
                        <CustomSnackBar variant="error" message="Erro ao enviar mídia" />
                    )}
                    {this.state.projetoCancelado && (
                        <CustomSnackBar variant="error" message={this.state.msgError} />
                    )}
                    <div className="columns is-multiline">
                        {listImovel.length > 0 ? (
                            listImovel.map((imovel, index) => (
                                <NewCardMidia
                                    key={index}
                                    atendimento={(tipo, idProjeto) =>
                                        this.atendimentoFotos(tipo, idProjeto)
                                    }
                                    imovel={imovel}
                                    midias={this.changeListMidiaPage}
                                    listarImoveis={() => this.listarImoveis()}
                                    index={index}
                                    detalhe={(index, idImovel) => this.detalhesState(index, idImovel)}
                                    openModalVideo={this.state.openModalVideo}
                                    modalVideo={this.modalVideo}
                                    snackSuccess={this.sucessSend}
                                    snackError={this.errorSend}
                                />
                            ))
                        ) : (
                                <div style={{ height: "100%", width: "100%" }}>
                                    <h1 className="center-progress" style={{textAlign: 'center'}}>Sem demandas registradas</h1>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    midias: state.midias
})

const mapActionsCreators = {
    ...Creators,
    ...AppCreators
}

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsCreators
    )(Midias)
)
