import React, { useState, useEffect } from "react"
import If from "../common/IF"

const ModalObservacoes = props => {
    const [observacao, setObservacao] = useState("")
    const [form, setForm] = useState(false)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setObservacao("")
        setShowModal(true)
        setForm(false)
        console.log('obs~> ', props.item)
    }, [props.item])

    const close = () => {
        setForm(false)
        setShowModal(false)
        props.close()
    }

    function formataData(data) {

        var d = new Date(data);

        var f = d.toLocaleString();

        return f;

    }

    function cadastraFormELimpa(observacao, item) {
        console.log('criado obs~> ', item)
        props.criarObservacao(observacao, item)
        setObservacao('')
        setForm(false)
    }

    return (
        <If test={showModal === true}>
            <div
                zIndex={2000000002}
                key={props.item.id}
                className="modal animated fadeIn faster"
            >
                <div className="modal-background" />
                <div className="modal-container">
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Imóvel: {props.item.idImovel.id}</p>
                            <button
                                onClick={() => close()}
                                className="button fechar-modal"
                            >Fechar</button>
                        </header>
                        <section className="modal-card-body">
                            {props.item.endereco}
                            <div className="clear10" />
                            <If test={form !== true}>
                                <button
                                    onClick={() => setForm(true)}
                                    className="button is-info is-small"
                                >
                                    Nova Observação
                                </button>
                            </If>
                            <If test={form === true}>
                                <div className="observacao">
                                    <div className="field">
                                        <div className="control">
                                            <textarea
                                                value={observacao}
                                                onChange={e => setObservacao(e.target.value)}
                                                className="textarea"
                                                placeholder="Observação"
                                                rows="2"
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                    </div>
                                </div>
                            </If>
                            <strong>Observações</strong>
                            {props.observacoes &&
                                props.observacoes.map(obs => (
                                    <div className="card" key={`observacao-${obs.id}`}>
                                        <div className="card-content">
                                            <strong>{formataData(obs.created)}</strong>
                                            <p>{obs.descricao}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </section>
                        <footer className="modal-card-foot">

                            <button
                                className="button is-info is-small"
                                onClick={() => cadastraFormELimpa(observacao, props.item.id)}
                            >
                                Salvar
                        </button>
                            <button
                                className="button is-info is-small"
                                onClick={() => setForm(false)}
                            >
                                Cancelar
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        </If >
    )
}
export default ModalObservacoes
