import history from "../../routes/history"

export const Types = {
    LOADING: `app/loading`,
    SIDEBAR: `app/sidebar`,
    PAGE: `app/page`,
    MESSAGE: `app/message`
}

const INITIAL_STATE = {
    loading: false,
    sidebar: true,
    page: "/atendimentos",
    message: {
        open: false,
        title: "",
        text: "",
        type: "link"
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.LOADING:
            console.log("app", action)
            return { ...state, loading: action.payload.loading }

        case Types.SIDEBAR:
            return { ...state, sidebar: action.payload.sidebar }

        case Types.PAGE:
            return { ...state, page: action.payload }
        case Types.MESSAGE:
            return { ...state, message: action.payload }

        default:
            return state
    }
}

export const Creators = {
    loadding: loading => {
        return {
            type: Types.LOADING,
            payload: { loading }
        }
    },
    showMenu: sidebar => {
        return {
            type: Types.SIDEBAR,
            payload: sidebar
        }
    },
    goToPage: page => dispatch => {
        dispatch({
            type: Types.PAGE,
            payload: page
        })
        history.push(page)
    },
    openMessage: text => {
        return {
            type: Types.MESSAGE,
            payload: {
                open: false,
                type: "link",
                text: text
            }
        }
    },
    closeMessage: () => {
        return {
            type: Types.MESSAGE,
            payload: {
                open: false,
                type: "link",
                text: ""
            }
        }
    }
}
