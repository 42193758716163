import React, { useState, useEffect } from "react"
import If from "../common/IF"
import api from '../../services/api'

function ModalDetalhesProprietario(props) {
    const [showModal, setShowModal] = useState(false)
    const [showData, setShowData] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        setShowModal(true)
        getData()
        console.log(`mostrar~> ${props.showModalDetalhe} teste~> ${showModal}`)
    }, [props.showModalDetalhe])

    const close = () => {
        props.close()
        setShowModal(false)
    }

    async function getData() {
        api.get(`/imoveis/dadosPessoasImovel/${props.idImovel}`).then((response) => {
            console.log('imovelInfo~> ', response)
            setData(response.data)
            setShowData(true)
            //alert(response.data.idImovel)
        }).catch((err) => console.log('Error', err.response))
    }

    const renderData = (value, index) => (
        <section className="modal-card-body">
            <If test={index === 0}>
                <strong>Proprietário Principal:</strong>
                <p>Nome: {value.Nome}</p>
                <p>Email: {value.Email}</p>
                {(value.Telefones.map((valueTelefones) => (
                    <p>Telefone: {valueTelefones}</p>
                )))}
            </If>
            <br />
            <If test={index !== 0}>
                <p>Proprietário nº {index}</p>
                <p>Nome: {value.Nome}</p>
                <p>Email: {value.Email}</p>
                {(value.Telefones.map((valueTelefones) => (
                    <p>Telefone: {valueTelefones}</p>
                )))}
            </If>
        </section>
    )

    return (
        <>
            <div
                zIndex={200000000002}
                key={Math.random()}
                className="modal animated fadeIn faster"
            >
                <div className="modal-background" />
                <div className="modal-container">
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title" >Proprietários do Imóvel: {props.idImovel}</p>
                            <button
                                className="button fechar-modal"
                                onClick={() => close()}
                            ><strong>fechar</strong></button>
                        </header>
                        {((showData === true) && (data.proprietarios.length > 0)) && (data.proprietarios.map((value, index) => (
                            renderData(value, index)
                        )))}
                    </div>
                </div>
            </div>
            )}
        </>
    )
}

export default ModalDetalhesProprietario
