import React, { Component } from 'react'
import logo from '../images/logo_color.png'
import api from "../services/api"
import history from '../routes/history'

class Obrigado extends Component {

    state = {
        showNotification: false,
        notification: {}
    }

    componentDidMount() {
        api.post(`consultor/confirmarEmail`)
            .then(response => console.log('confirmEmail', response.data))
            .catch(error => console.log('err confirmEmail', error.response))
    }

    loginRedirect() {
        history.push('/consultor/login')
    }

    render() {

        return (
            <>
                <div className="content-login">
                    <div className="card container-login">
                        <div className="card-content card-login">

                            <img className="img-login" src={logo} alt="" />
                            <h4 className="title is-5">Cadastro Efetuado com Sucesso</h4>
                            <p style={{ textAlign: 'center' }}>Foi enviado um e-mail para você. <br /> Clique no link enviado para confirmar o e-mail</p>

                            <button onClick={() => this.loginRedirect()} className="btn-login">Ir para o início</button>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Obrigado