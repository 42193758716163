import * as service from "../../services/atendimento"

import { Creators as AppCreators } from "./app"

export const Types = {
    FORM: "atendimentos/form",
    FECTH: "atendimentos/fecth",
    MODAL: "atendimentos/modal",
    CREATE_OBSERVACAO: "atendimentos/create_observacao"
}

const INITIAL_STATE = {
    loading: false,
    data: [],
    modal: {
        observacao: false,
        atendimento: false
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FECTH:
            return { ...state, data: action.payload.data }

        case Types.MODAL:
            return {
                ...state,
                modal: { ...state.modal, [action.payload.modal]: action.payload.open }
            }

        case Types.CREATE_OBSERVACAO:
            return { ...state, data: state.data.filter(item => item.id !== action.payload.id) }

        default:
            return state
    }
}

export const Creators = {
    fetch: () => dispatch => {
        dispatch(AppCreators.loadding(true))
        service
            .get()
            .then(response => {
                dispatch({
                    type: Types.FECTH,
                    payload: { data: response.data }
                })
                dispatch(AppCreators.loadding(false))
            })
            .catch(err => {
                console.log(err)
            })
    },
    modal: (ref, open) => {
        return {
            type: Types.MODAL,
            payload: { modal: ref, open }
        }
    },
    salveObservacao: data => dispatch => {
        dispatch({
            type: Types.MODAL,
            payload: { modal: "observacao", open: false }
        })

        dispatch(AppCreators.loadding(true))

        service
            .create({
                data: "08/05/2019",
                observacao: `Nova observacao criada no sistema.`
            })
            .then(data => {
                dispatch(AppCreators.loadding(false))
                dispatch(AppCreators.openMessage("Observação criada com sucesso!"))
                dispatch({
                    type: Types.CREATE_OBSERVACAO,
                    payload: data
                })
            })
    }
}
