import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Creators } from "../store/ducks/atendimento"

import Navbar from "../components/menu/Navbar"
import Loading from "../components/common/Loading"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import CustomSnackBar from '../components/material/SnackBar'


class MeusDados extends React.Component {
    state = {
        contaCadastrada: false,
        open: true,
        hamburguer: true,
        idClient: localStorage.getItem('id'),
        disabledAg: false,
        listaAgencia: [],
        btnDisabled: true,
        contatos: [],
        email: '',
        fone: '',
        nome: '',
        documento: '',
        tipo: "pf",
        rg: '',
        cep: '',
        telefone: '',
        telefone2: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        IdMunicipio: '',
        IdEstado: '',
        tipoconta: '',
        operacao: '',
        bancoid: '',
        agenciaid: '',
        numeroConta: '',
        cidade: '',
        estado: '',
        showSucess: false
    }

    componentDidMount() {
        this.pegarDados()
        this.setState({
            IdMunicipio: 879,
            IdEstado: 9,
        })
    }

    componentWillMount() {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
    }

    pegarDados() {
        this.setState({
            open: true
        })
        api.get(`/clientes/${this.state.idClient}`)
            .then((response) => {
                console.log('dados cliente ', response.data)
                this.setState({
                    email: response.data.email,
                    fone: response.data.telefone,
                    nome: response.data.nome,
                    documento: response.data.documento,
                    tipo: response.data.tipo,
                    rg: response.data.rg,
                    cep: response.data.cep,
                    telefone: response.data.telefone,
                    telefone2: response.data.telefone_2,
                    logradouro: response.data.logradouro,
                    numero: response.data.numero,
                    complemento: response.data.complemento,
                    bairro: response.data.bairro,
                    contatos: response.data.contatos,
                    cidade: response.data.cidade.descricao,
                    estado: response.data.estado.descricao,
                    tipoconta: response.data.contas !== undefined ? response.data.contas[0].tipo_conta.id : "",
                    operacao: response.data.contas !== undefined ? response.data.contas[0].operacao : "",
                    bancoid: response.data.contas !== undefined ? response.data.contas[0].banco.id : "",
                    agenciaid: response.data.contas !== undefined ? response.data.contas[0].agencia.id : "",
                    numeroConta: response.data.contas !== undefined ? response.data.contas[0].numero : "",
                    open: false,
                })
            }).catch((error) => {
                console.log(error)
                this.setState({
                    open: false
                })
            })
    }

    selecionarAgencia(bancoid) {
        this.setState({
            bancoid,
        })
        api.get(`/clientes/agencias/${bancoid}`)
            .then((response) => {
                this.setState({
                    listaAgencia: response.data.agencias,
                    disabledAg: true
                })
            })
        console.log(this.state.bancoid)
    }

    alterarDados() {
        this.setState({ open: true })
        const documento = this.state.documento
        const doc2 = documento.replace('.', "").replace('-', "").replace('.', "")
        api.put(`/clientes/${this.state.idClient}`,
            {
                email: this.state.email,
                nome: this.state.nome,
                documento: doc2,
                tipo: "pf",
                rg: this.state.rg,
                cep: this.state.cep,
                telefone: this.state.fone,
                telefone2: this.state.fone,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                complemento: this.state.complemento,
                bairro: this.state.bairro,
                tipoconta: this.state.tipoconta,
                operacao: this.state.operacao,
                bancoid: this.state.bancoid,
                agenciaid: this.state.agenciaid,
                numeroConta: this.state.numeroConta,
            }
        ).then((response) => {
            console.log('Alterou')
            this.setState({
                btnDisabled: true,
                open: false,
                showSucess: true
            })
        }).catch((error) => {
            this.setState({
                btnDisabled: true,
                open: false
            })
            console.log('error alterar', error.response)
            alert(`Erro: ${error.response.data.message}`)
        })
    }

    habilitarEdicao() {
        this.setState({
            btnDisabled: !this.state.btnDisabled,
            showSucess: false
        })
        console.log(this.state.btnDisabled)
    }

    handleForm = (value, e) => {
        this.setState({
            [value]: e.target.value
        })
        console.log(value, e.target.value)
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    render() {
        return (
            <div className="main">
                <Loading open={this.state.open} />
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Meus Dados" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.btnDisabled === false &&
                        <CustomSnackBar variant="success"
                            message="Agora Você Pode Editar Seus Dados Pessoais" />}
                    {this.state.showSucess === true && <CustomSnackBar variant="success" message="Salvo com sucesso" />}
                    <div className="card">
                        <div className="card-content">
                            <h1>Quer Editar Seus Dados?</h1>
                            <div className="field is-grouped">
                                <div className="control">
                                    <button onClick={() => this.habilitarEdicao()}
                                        className={"button " + (this.state.btnDisabled ? "is-link" : "is-danger")}>
                                        {this.state.btnDisabled ? "Editar Dados" : "Cancelar"}
                                    </button>
                                </div>
                                <div className="control">
                                    {!this.state.btnDisabled && <button onClick={() => this.alterarDados()}
                                        className="button is-success">Salvar</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card">
                        <div className="card-content">
                            <h1>Dados pessoais</h1>
                            <div className="columns is-multiline">
                                <div className="column is-full">
                                    <div className="field">
                                        <label className="label">Nome:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.nome}
                                                onChange={(e) => this.handleForm('nome', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">RG:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.rg}
                                                onChange={(e) => this.handleForm('rg', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">CPF/CNPJ:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.documento}
                                                onChange={(e) => this.handleForm('documento', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="columns" style={{ padding: 0, margin: 0 }}>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">E-mail:</label>
                                            <div className="control">
                                                <input type="text"
                                                    className="input is-rounded"
                                                    value={this.state.email}
                                                    onChange={(e) => this.handleForm('email', e)}
                                                    disabled={this.state.btnDisabled} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Telefone:</label>
                                            <div className="control">
                                                <input type="text"
                                                    className="input is-rounded"
                                                    value={this.state.telefone}
                                                    onChange={(e) => this.handleForm('fone', e)}
                                                    disabled={this.state.btnDisabled} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="card">
                        <div className="card-content">
                            <h1>Endereço</h1>
                            <div className="columns is-multiline">
                                <div className="column is-full">
                                    <div className="field">
                                        <label className="label">Logradouro:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.logradouro}
                                                onChange={(e) => this.handleForm('logradouro', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-one-fifth">
                                    <div className="field">
                                        <label className="label">Número:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.numero}
                                                onChange={(e) => this.handleForm('numero', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-one-fifth">
                                    <div className="field">
                                        <label className="label">CEP:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.cep}
                                                onChange={(e) => this.handleForm('cep', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-one-third">
                                    <div className="field">
                                        <label className="label">Bairro:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={typeof(this.state.bairro) !== 'string' ? "Não informado" : this.state.bairro}
                                                onChange={(e) => this.handleForm('bairro', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-one-quarter">
                                    <div className="field">
                                        <label className="label">Complemento:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.complemento}
                                                onChange={(e) => this.handleForm('complemento', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half">
                                    <div className="field">
                                        <label className="label">Cidade:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.cidade}
                                                onChange={(e) => this.handleForm('cidade', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-two-fifths">
                                    <div className="field">
                                        <label className="label">Estado:</label>
                                        <div className="control">
                                            <input type="text"
                                                className="input is-rounded"
                                                value={this.state.estado}
                                                onChange={(e) => this.handleForm('estado', e)}
                                                disabled={this.state.btnDisabled} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    atendimento: state.atendimento,
    app: state.app
})

const mapActionsCreators = {
    ...Creators
}

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsCreators
    )(MeusDados)
)
