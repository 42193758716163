/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import { Modal } from 'reactstrap';
import "react-sweet-progress/lib/style.css";
import api from '../../services/api'
import If from '../common/IF'

class ModalObersevacaoMidia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      files: null,
      uploading: false,
      progresso: false,
      porcentagem: 0,
      statusProgress: 'active',
      logs: []
    };
    this.toggle = this.toggle.bind(this);
    this.loadLogs = this.loadLogs.bind(this)
  }

  toggle() {
    this.loadLogs()
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  loadLogs = () => {
    console.log('Vai chamar a api')
    api.get('/consultor/logProjetoFotos/' + this.props.idVisita.projetoId.id)
      .then(response => {
        console.log('Modal mais informações')
        this.setState({
          logs: response.data.logs
        })
      }).catch(error => console.log(error))
  }


  render() {
    return (
      <>
        <div className="button is-danger is-small" onClick={this.toggle}>
          <p>{this.props.buttonLabel}</p>
        </div>
        <If test={this.state.modal === true}>
          <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal" style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
            <div className="modal animated fadeIn faster">
              <div className="modal-background" />
              <div className="modal-card">
                <header className="modal-card-head">
                  <p className="modal-card-title">Status: {this.props.idVisita.projetoId.status} - {this.props.idVisita.projetoId.id}</p>
                  <button className="delete" aria-label="close" onClick={this.toggle}></button>
                </header>
                <section className="modal-card-body">
                  {this.state.logs.length > 0 ? this.state.logs.map(log => (
                    <div key={log.id}>
                      <hr />
                      <p>Titulo: {log.titulo}</p>
                      <p>Descrição: {log.descricao}</p>
                      <p>Data: {log.data}</p>
                      <hr />
                    </div>
                  )) : 'Esse Projeto não possui informações detalhadas'}
                </section>
                <footer className="modal-card-foot">
                  <button className={"button is-success " + (this.state.uploading ? "is-loading" : "")}
                    onClick={this.toggle}>Fechar</button>
                </footer>
              </div>
            </div>
          </Modal>
        </If>
      </>
    )
  }
}

export default ModalObersevacaoMidia;