import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Loading from "../components/common/Loading"

import { Creators } from "../store/ducks/consultor"
import api from "../services/api"

import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import CustomSnackBar from "../components/material/SnackBar"

class Parceiros extends React.Component {
    state = {
        hamburguer: true,
        open: false,
        isLogged: localStorage.getItem("token"),
        consultores: [],
        permissoes: [],
        novasPermissoes: [],
        idTipos: [],
        idCliente: "",
        response: ""
    }

    componentWillMount() {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false
            })
        }
    }

    componentDidMount() {
        let idCliente = localStorage.getItem("id")
        this.setState({ idCliente })
        api.get("/clientes/tiposPessoas").then(response => {
            let pessoas = response.data.tipos
            let consultores = []
            pessoas.forEach(pessoa => {
                if (pessoa.id !== 1) {
                    consultores.push(pessoa)
                }
            })
            this.setState({ consultores })
        })
        api.get(`/clientes/${idCliente}`).then(response => {
            let tipos = response.data.tipo_cliente
            let permissoes = []
            tipos.forEach(item => {
                permissoes.push(item.idTipo)
            })
            this.setState({ permissoes })
        })
    }

    changeHamburger = () => {
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }
    changePermissoes(id) {
        let novasPermissoes = this.state.novasPermissoes

        let index = novasPermissoes.indexOf(id)
        if (index !== -1) {
            novasPermissoes.splice(index, 1)
        } else {
            novasPermissoes.push(id)
        }
        this.setState({ novasPermissoes })
    }

    async solicitarPermissao() {
        this.setState({ open: true })
        let idCliente = this.state.idCliente
        api.put(`/consultor/requestPermission/${idCliente}`, {
            tipo_cliente: this.state.novasPermissoes
        }).then((response) => {
            console.log('soliPermiss~> ', response)
            this.setState({
                response: response.data.Message,
                open: false
            })
            this.componentDidMount()
        }).catch((err) => {
            this.setState({ open: false })
            console.log('error soliPermiss~> ', err)
        })
    }

    render() {
        return (
            <div className="main">
                {this.state.response && (
                    <CustomSnackBar variant="success" message={this.state.response} />
                )}
                <Loading open={this.state.open} />
                <Sidebar
                    hamburguer={this.state.hamburguer}
                    changeHamburger={this.changeHamburger}
                />
                <Navbar
                    title="Parceiros"
                    hamburguer={this.state.hamburguer}
                    changeHamburger={this.changeHamburger}
                />
                <div
                    className={
                        "animated content " +
                        (this.state.hamburguer
                            ? "paddingLeftSide bounceInLeft fast"
                            : "bounceInRight fast")
                    }
                >
                    <div className="card">
                        <div className="card-content">
                            <div>
                                <h2>Parceiros Plano B</h2>
                                <p style={{ fontSize: 18 }}>
                                    resumo do que é os parceiro do planob e explica que ele tem vai
                                    solicitar uma nova permissao
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{ marginTop: 20 }}>
                        <div className="card-content">
                            <div>
                                <h3>Tipos de parceiros</h3>
                                {this.state.consultores.map(consultor => {
                                    if (!this.state.permissoes.includes(consultor.id)) {
                                       return ( 
                                        <div className="lista-parceiro">
                                            {
                                                <label
                                                    key={`consultor_${consultor.id}`}
                                                    className="checkbox"
                                                    style={{ marginLeft: 10 }}
                                                >
                                                    <input
                                                        style={{marginRight: 5}}
                                                        type="checkbox"
                                                        value={consultor.id}
                                                        onChange={() =>
                                                            this.changePermissoes(consultor.id)
                                                        }
                                                    />
                                                    {consultor.descricao}
                                                </label>
                                            }
                                        </div>)
                                    }
                                    return ""
                                })}
                                {this.state.permissoes.length < 5 ? (
                                    <button
                                        onClick={() => this.solicitarPermissao()}
                                        style={{ marginLeft: 10, marginTop: -5 }}
                                        className="button is-primary"
                                    >
                                        Enviar
                                    </button>
                                ) : (
                                        <p>Você ja solicitou todos os tipos de parceiros</p>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    consultor: state.consultor
})

const mapActionsCreators = {
    ...Creators
}

export default withRouter(connect(mapStateToProps, mapActionsCreators)(Parceiros))
