import axios from "axios"
console.log("ENV", process.env)
const urls = {
    planob: "https://planob.classeloc.com.br/planob",
    testing: "https://testing.classeloc.com.br/planob",
    local: "http://local.classeloc.com.br/planob"
}
const api = axios.create({
    baseURL: process.env.REACT_APP_API
        ? process.env.REACT_APP_API_HOST
            ? process.env.REACT_APP_API_HOST
            : urls[process.env.REACT_APP_API]
        : urls["planob"]
})

api.interceptors.request.use(async config => {
    const token = localStorage.getItem("token")
    config.headers.Authorization = token != null ? `Bearer ${token}` : `Bearer `
    config.headers.Cliente = "f89babe6652a235f383b7991f25df091"
    config.headers.Secret = "a4eb02c7e4bb24afaa556290c6944498"
    config.headers.fcmToken =
        localStorage.getItem("fcmtoken") !== null ? localStorage.getItem("fcmtoken") : ""
    return config
})

export default api
