import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Creators } from "../store/ducks/midias"
import { Creators as AppCreators } from "../store/ducks/app"

import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import Galeria from '../components/material/Galeria'
import CustomSnackBar from '../components/material/SnackBar'
import If from "../components/common/IF"

class ListagemMidias extends React.Component {
    state = {
        modalApagarVideo: false,
        deleteSuccess: false,
        deleteError: false,
        loadingDeleteVideo: false,
        indexVideo: '',
        tabs: false,
        hamburguer: true,
        fotos: [],
        videos: [],
        loading: false,
        modalIsOpen: false,
        idVideoApagar: ''
    }

    componentWillMount() {
        this.loadFotoVideo()
    }

    modalApagarVideo = index => {
        console.log(this.state.videos[index])

        this.setState({
            modalApagarVideo: !this.state.modalApagarVideo,
            idVideoApagar: this.state.videos[index].id,
            indexVideo: index
        })
    }

    handleTabsPicture = () => {
        this.setState({
            tabs: true
        })
    }

    handleTabsVideo = () => {
        this.setState({
            tabs: false
        })
    }

    loadFotoVideo = () => {
        const idImoveis = this.props.location.state.idImovel
        const idProjeto = this.props.location.state.idProjeto

        console.log(`idIm~> ${idImoveis}, idPro~> ${idProjeto}`)

        api.get(`/consultor/getFotosProjeto/${idImoveis}/${idProjeto}`)
            .then((response)=>{
                console.log('getProjeto~> ', response)
                this.setState({
                    fotos: response.data.fotos,
                    videos: response.data.videos
                })
            }).catch((error)=>{
                console.log('Erro listagem ', error.response)
            })

        //                            videos: imovel.videos

    }

    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    }

    verMidias = imovel => {
        this.setState({ imovel })
        this.props.modal(true)
    }

    apagar(id, index) {
        api.delete(`/consultor/deleteFoto/${id}`)
            .then((response) => {
                console.log(response.data)
                var beforeList = this.state.fotos
                delete beforeList[index]
                this.setState({
                    fotos: beforeList
                })
            })
            .catch((response) => console.log(response.data))
    }

    apagarVideo = () => {
        const index = this.state.indexVideo
        this.setState({
            loadingDeleteVideo: true,
        })
        api.delete(`/consultor/deleteVideo/${this.state.idVideoApagar}`)
            .then((response) => {
                console.log(response.data)
                var beforeList = this.state.videos
                delete beforeList[index]
                this.setState({
                    videos: beforeList,
                    loadingDeleteVideo: false,
                    indexVideo: '',
                    deleteSuccess: true,
                    modalApagarVideo: false
                })
            })
            .catch((error) => {
                console.error(error)
                this.setState({
                    loadingDeleteVideo: false,
                    indexVideo: '',
                    modalApagarVideo: false,
                    deleteError: true,
                })
            })
        setTimeout(() => {
            this.setState({
                deleteSuccess: false,
                deleteError: false,
            })
        }, 4000);
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    render() {
        return (
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Foto Imóvel" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.deleteSuccess &&
                        <CustomSnackBar variant="success"
                            message="Vídeo Apagado Com sucesso" />}
                    {this.state.deleteError &&
                        <CustomSnackBar variant="error"
                            message="Vídeo Apagado Com sucesso" />}
                    <div className="tabs is-centered">
                        <ul>
                            <li className={(this.state.tabs ? "is-active" : "")}>
                                {// eslint-disable-next-line
                                    <a onClick={this.handleTabsPicture}>
                                        <span className="icon is-small"><i className="fas fa-image" aria-hidden="true"></i></span>
                                        <span>Fotos</span>
                                    </a>}
                            </li>
                            <li className={(this.state.tabs ? "" : "is-active")}>
                                {// eslint-disable-next-line
                                    <a onClick={this.handleTabsVideo}>
                                        <span className="icon is-small"><i className="fas fa-film" aria-hidden="true"></i></span>
                                        <span>Videos</span>
                                    </a>}
                            </li>
                        </ul>
                    </div>
                    {this.state.tabs && <Galeria fotos={this.state.fotos} />}
                    {!this.state.tabs &&
                        <div className="columns is-multiline">
                            {this.state.videos.map((video, index) =>
                                <div className="card column is-one-third">
                                    <div className="card-content">
                                        <p>{video.id}</p>
                                        <video width="400px" height="400px" loop controls muted>
                                            <source src={video.link} type="video/mp4" />
                                        </video>
                                        <br />
                                        {/*<button onClick={() => this.apagarVideo(video.id, index)}
                                            className={"button is-danger is-centered is-small " + (this.state.loadingDeleteVideo && this.state.indexVideo === index ? "is-loading" : "")}>Apagar</button>*/}
                                        {this.state.modalApagarVideo && <div className="modal" style={{ backgroundColor: 'rgba(52, 52, 52, 0.2)' }}>
                                            <div className="modal-card">
                                                <header className="modal-card-head">
                                                    <p className="modal-card-title" >Apagar Vídeo</p>
                                                    <button className="delete" aria-label="close" onClick={() => this.setState({ modalApagarVideo: false })}></button>
                                                </header>
                                                <section className="modal-card-body">
                                                    Você Tem Certeza Que Deseja Apagar Esse Vídeo?
                                                </section>
                                                <footer className="modal-card-foot">
                                                    <button className={"button is-danger is-centered" + (this.state.loadingDeleteVideo && this.state.indexVideo === index ? "is-loading" : "")}
                                                        onClick={() => this.apagarVideo(index)}>Apagar</button>
                                                    <button className="button" onClick={() => this.setState({ modalApagarVideo: false })}>Cancelar</button>
                                                </footer>
                                            </div>
                                        </div>}
                                        <If test={video.liberado !== "sim"}>
                                            <button onClick={() => this.modalApagarVideo(index)}
                                                className="button is-danger is-centered is-small">Apagar Vídeo</button>
                                        </If>
                                        <If test={video.liberado === "sim"}>
                                            <button onClick={() => this.modalApagarVideo(index)}
                                                className="button is-link is-centered is-small" disabled>Vídeo Aprovado</button>
                                        </If>
                                    </div>
                                </div>
                            )}
                        </div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    midias: state.midias
})

const mapActionsCreators = {
    ...Creators,
    ...AppCreators
}

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsCreators
    )(ListagemMidias)
)
