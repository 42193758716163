import React, { useEffect, useState } from "react"
import If from "../common/IF"
import api from "../../services/api"

const styles = {
    bordaTermo: {
        borderWidth: 1,
        marginBottom: 10,
        borderRadius: 5,
        borderStyle: "solid",
        height: 30,
        flex: 1,
        flexDirection: "row",
        borderColor: "#bfbfbf",
        justifyContent: 'center',
        alignItems: 'center'
    },
    direita: {
        textAlign: "right",
        marginTop: -22,
        marginRight: 5
        //marginTop: (window.outerWidth < 760) ? null : -22,
        //marginRight: (window.outerWidth < 760) ? null : 5,
        //justifyContent: 'center', alignItems: 'center'
    }
}

export default function ModalTermosUso(props) {
    const [showModal, setShowModal] = useState(false)
    const [links, setLinks] = useState({})
    const [showData, setShowData] = useState(false)

    useEffect(() => {
        setShowModal(props.showModal)
        pegarLinks()
    }, [props.showModal])

    async function pegarLinks() {
        const url = "/textos/linkstermos"

        api.get(url)
            .then(response => {
                setLinks(response.data.links)
                setShowData(true)
            })
            .catch(err => console.log("err termosUso", err.response))
    }

    const close = () => {
        setShowModal(false)
        props.close()
    }

    return (
        <If test={showModal === true}>
            <div zIndex={200000000002} key={Math.random()} className="modal animated fadeIn faster">
                <div className="modal-background" />
                <div className="modal-container">
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Links para os Termos de Uso.</p>
                            <button className="button fechar-modal" onClick={() => close()}>
                                <strong>fechar</strong>
                            </button>
                        </header>
                        <section className="modal-card-body">
                            {showData === true && (
                                <>
                                    <div style={styles.bordaTermo}>
                                        <div style={{ paddingLeft: 5, color: "#000" }}>
                                            Termos de uso do indicador:{" "}
                                        </div>
                                        <div style={styles.direita}>
                                            <a
                                                target="_blank"
                                                href={links.LinkTermoIndicador}
                                                rel="noopener noreferrer"
                                            >
                                                abrir
                                            </a>
                                        </div>
                                    </div>
                                    <div style={styles.bordaTermo}>
                                        <div style={{ paddingLeft: 5, color: "#000" }}>
                                            Termos de uso do consultor de Imóveis:{" "}
                                        </div>
                                        <div style={styles.direita}>
                                            <a
                                                target="_blank"
                                                href={links.linkTermoConsultorCorretor}
                                                rel="noopener noreferrer"

                                            >
                                                abrir
                                            </a>
                                        </div>
                                    </div>
                                    <div style={styles.bordaTermo}>
                                        <div style={{ paddingLeft: 5, color: "#000" }}>
                                            Termos de uso do consultor de Vistoria:{" "}
                                        </div>
                                        <div style={styles.direita}>
                                            <a
                                                target="_blank"
                                                href={links.linkTermoConsultorDeVistoria}
                                                rel="noopener noreferrer"
                                            >
                                                abrir
                                            </a>
                                        </div>
                                    </div>
                                    <div style={styles.bordaTermo}>
                                        <div style={{ paddingLeft: 5, color: "#000" }}>
                                            Termos de uso do consultor de Imagem:{" "}
                                        </div>
                                        <div style={styles.direita}>
                                            <a
                                                target="_blank"
                                                href={links.LinkTermoConsultorDeFotos}
                                                rel="noopener noreferrer"
                                            >
                                                abrir
                                            </a>
                                        </div>
                                    </div>
                                </>
                            )}
                        </section>
                    </div>
                </div>
            </div>
        </If>
    )
}
