import React from "react"
import { Router, Route, Switch, Redirect } from "react-router-dom"

import history from "./history"

import Atendimentos from "../pages/Atendimentos"
import Midias from "../pages/Midias"
import Login from "../pages/Login"
import RecuperarSenha from "../pages/RecuperarSenha"
import Cadastro from "../pages/Cadastro"
import ListagemMidias from "../pages/ListagemMidias"
import MeusDados from "../pages/MeusDados"
import Comissoes from "../pages/Comissoes"
import Indicacoes from "../pages/Indicacoes"
import Vistorias from "../pages/Vistorias"
import Duvidas from "../pages/Duvidas"
import DadosBancarios from "../pages/DadosBancarios"
import Confirmado from "../pages/Confirmado"
import Obrigado from "../pages/Obrigado"

import Parceiros from "../pages/Parceiros"
import Laudo from "../pages/Laudo"
import CadastroAmbiente from "../pages/CadastroAmbiente"
import InfoAmbiente from "../pages/InfoAmbiente"
import FotosLaudo from "../pages/FotosLaudo"
import DetalhesVistoria from "../pages/DetalhesVistoria"
import api from "../services/api"

const isAuthenticated = async () => {
    if (localStorage.getItem("token")) {
        let retorno = await api.get("/auth/verificaToken")

        console.log(retorno)

        if (retorno.data.message === "success") {
            console.log("entrou aqui")
            return true
        } else {
            console.log("entrou erro")
            history.push("/consultor/login")
            return false
        }
    }
    return false
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/consultor/login", state: { from: props.location } }} />
            )
        }
    />
)

const Routes = () => (
    <div className="page-wrap">
        <Router history={history}>
            <Switch>
                <Route exact path="/consultor/login" component={Login} />
                <Route exact path="/consultor/recuperar-senha" component={RecuperarSenha} />
                <Route exact path="/consultor/cadastro" component={Cadastro} />
                <Route exact path="/confirmado" component={Confirmado} />
                <Route exact path="/obrigado" component={Obrigado} />
                <PrivateRoute exact path="/midias/listagem" component={ListagemMidias} />
                <PrivateRoute exact path="/dadosbancarios" component={DadosBancarios} />
                <PrivateRoute exact path="/meusdados" component={MeusDados} />
                <PrivateRoute exact path="/comissoes" component={Comissoes} />
                <PrivateRoute exact path="/vistorias" component={Vistorias} />
                <PrivateRoute exact path="/laudo/:id" component={Laudo} />
                <PrivateRoute exact path="/indicacoes" component={Indicacoes} />
                <PrivateRoute exact path="/duvidas" component={Duvidas} />
                <PrivateRoute path="/atendimentos" component={Atendimentos} />
                <PrivateRoute path="/midias" component={Midias} />
                <PrivateRoute path="/parceiros" component={Parceiros} />
                <PrivateRoute path="/app" component={() => <h1>App</h1>} />
                <PrivateRoute exact path="/" component={Atendimentos} />
                <PrivateRoute path="/novoambiente/:id" component={CadastroAmbiente} />
                <PrivateRoute path="/infoambiente/:id" component={InfoAmbiente} />
                <PrivateRoute path="/fotoslaudo/:id" component={FotosLaudo} />
                <PrivateRoute path="/vistoriadetalhes/:id" component={DetalhesVistoria} />   
                <Route path="*" component={() => <h1>Page not found</h1>} />
            </Switch>
        </Router>
    </div>
)

export default Routes
