import React from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import "../styles/login.css"

import CustomSnackBar from "../components/material/SnackBar"
import Input from "../components/forms/Input"
import api from "../services/api"
import InputMask from "react-input-mask"
import history from "../routes/history"
import logo from "../images/logo_color.png"
//import { messaging, pedirPermissaoParaReceberNotificacoes } from "../push-notification"

class Login extends React.Component {
    state = {
        erroMsg: false,
        errorPassword: false,
        resetOk: false,
        passwordMessage: false,
        forgotPassword: false,
        receiveCode: false,
        documento: "",
        senha: "",
        fcmtoken: "",
        codigo: "",
        tipo: "2",
        notification: false,
        naoAutorizado: false
    }

    componentDidMount() {
        //this.fireInit()
    }

    /*fireInit = () => {
        localStorage.clear()
        pedirPermissaoParaReceberNotificacoes(messaging).then(token => {
            console.log('fcmtoken~> ', token)
            this.setState({fcmtoken: token})
            localStorage.setItem("fcmtoken", token)
        })
    }*/

    async login() {
        const documento = this.state.documento
        const doc2 = documento
            .replace(".", "")
            .replace("-", "")
            .replace(".", "")
        console.log("teste", doc2)
        console.log("State", this.state)
        
        this.setState({
            documento: doc2
        })

        console.log('fcmtoken teste~> ', this.state.fcmtoken)

        const body = {
            tipo: "pf",
            senha: this.state.senha,
            documento: doc2,
            fcmtoken: (this.state.fcmtoken) ? this.state.fcmtoken : '',
            tipo_cliente: this.state.tipo
        }

        console.log('body~> ', body)
        
        api.post("/auth/login", body)
            .then(response => {
                console.log("status", response)
                localStorage.setItem("token", response.data.token)
                localStorage.setItem("nome", response.data.user.name)
                localStorage.setItem("email", response.data.user.email)
                localStorage.setItem("email_confirmado", response.data.user.email_confirmado)
                localStorage.setItem("id", response.data.user.id)
                localStorage.setItem("tipo", this.state.tipo)
                switch (this.state.tipo) {
                    case "2":
                        history.push("/indicacoes")
                        break
                    case "3":
                        history.push("/midias")
                        break
                    case "4":
                        history.push("/")
                        break
                    case "5":
                        history.push("/vistorias")
                        break
                    default:
                        history.push("/")
                        break
                }
                console.log("Terminou")
            })
            .catch(error => {
                console.log('erro geral~> ', error)
                console.log('erro~> ', error.response)
                if (error.response.data.status === 401) {
                    this.setState({
                        naoAutorizado: true
                    })
                } else if (error.response.data.status === 403) {
                    this.setState({
                        errorPassword: true,
                        erroMsg: error.response.data.message
                    })
                }else if(error.response.data.status === 500){
                    this.setState({
                        errorPassword: true,
                        erroMsg: error.response.data.message
                    })
                }
            })
            .finally(
                this.setState({
                    naoAutorizado: false,
                    notification: false,
                    errorPassword: false
                })
            )
    }


    changeState = (state, value) => {
        this.setState({
            [state]: value
        })
        console.log(this.state)
    }

    changeUrlState(state, value) {
        this.props.location.state[state] = value
    }

    handleOptions = async e => {
        await this.setState({
            tipo: e.target.value
        })
        console.log(this.state.tipo)
    }

    render() {
        console.log(window.innerWidth)
        return (
            <div className="content-login">
                <div className="card container-login">
                    <div className="card-content card-login">
                        {this.state.errorPassword && (
                            <CustomSnackBar variant="error" message={this.state.erroMsg} />
                        )}
                        {this.state.notification && (
                            <CustomSnackBar
                                variant="success"
                                message="Conta cadastrada com sucesso. Vamos analisar seu cadastro para 
                            liberar o seu acesso"
                            />
                        )}
                        {this.state.naoAutorizado && (
                            <CustomSnackBar
                                variant="error"
                                message="Sua conta ainda não foi liberada"
                            />
                        )}

                        <img className="img-login" src={logo} alt="" />
                        <h4 className="title is-5">Login</h4>

                        <div>
                            <InputMask
                                mask="999.999.999-99"
                                defaultValue={this.state.documento}
                                onChange={e => this.changeState("documento", e.target.value)}
                            >
                                {() => (
                                    <div className="field">
                                        <div className="control has-icons-left has-icons-right">
                                            <input
                                                className="input"
                                                placeholder="Informe Seu CPF"
                                                defaultValue={this.state.documento}
                                            />
                                            <span className="icon is-small is-left">
                                                <i className="fas fa-address-card"></i>
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </InputMask>

                            <Input
                                type="password"
                                placeholder="Senha"
                                icon="fas fa-user"
                                chave="senha"
                                value={this.state.senha}
                                onChange={(e) => this.setState({senha: e.target.value})}
                            />

                            <div className="select-cadastro">
                                <div className="columns">
                                    <label className="column label-login" htmlFor="estado">
                                        Logar Como:{" "}
                                    </label>
                                    <div className="column is-7">
                                        <div className="select">
                                            <select onChange={e => this.handleOptions(e)}>
                                                <option value="2">Indicador</option>
                                                <option value="3">Consultor de Fotos</option>
                                                <option value="4">Corretor</option>
                                                <option value="5">Vistoriador</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <button onClick={() => this.login()} className="btn-login">
                                    Login
                                </button>
                                <Link
                                    to="/consultor/cadastro"
                                    className="nova-conta"
                                    style={{ color: "#3498db", margin: "auto" }}
                                >
                                    <p style={{ justifyContent: "center", alignItems: "center" }}>
                                        Criar nova conta
                                    </p>
                                </Link>
                            </div>
                            <Link
                                to="/consultor/recuperar-senha"
                                style={{ color: "#3498db", cursor: "pointer" }}
                                onClick={this.handleForgotPassword}
                            >
                                Esqueci Minha Senha
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    imovel: state.imovel
})

export default withRouter(connect(mapStateToProps)(Login))
