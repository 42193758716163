import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import Ambientes from "../components/cards/Ambientes"
import { Link } from "react-router-dom"
import history from '../routes/history'
import CustomSnackBar from "../components/material/SnackBar"

class Laudo extends Component {

    state = {
        hamburguer: true,
        ambientes: [],
        "error": false,
        "message": ""
    }

    componentWillMount = () => {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        this.listarVistorias()
    }

    listarVistorias = () => {

        var id = this.props.match.params.id;
        api.get('/vistorias/listarAmbientes/' + id)
            .then(response => this.setState({
                ambientes: response.data.ambientes
            }))
    }

    enviar = () => {

        let self = this;

        var id = this.props.match.params.id;
        api.put('/vistorias/finalizarLaudo', {
            "vistoria": id
        })
            .then(function (response) {

                if (response.data.message === "sucesso") {

                    history.push({
                        pathname: "/vistorias"
                    })

                } else {

                    self.setState({ "error": true });
                    self.setState({ "message": response.data.message });

                    setTimeout(() => {
                        self.setState({
                            error: false,
                        })
                        self.setState({
                            message: '',
                        })
                    }, 3000);

                }

            })


    }

    render() {
        return (
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>

                    {this.state.error === true &&
                        <CustomSnackBar variant="error" message={this.state.message} />
                    }


                    <div className="card">
                        <div className="card-content">
                            <h4 className="card-title">Laudo</h4>
                            <Link to={"/novoambiente/" + this.props.match.params.id} style={{ marginRight: 10 }} className="button is-info">Novo Ambiente</Link>
                            <Link to={"/fotoslaudo/" + this.props.match.params.id} style={{ marginRight: 10 }} className="button is-info">Fotos</Link>
                            <button className="button" onClick={this.enviar}>Enviar Laudo</button>
                        </div>

                        {
                            this.state.ambientes.map((ambiente, index) => (
                                <Ambientes
                                    key={index}
                                    ambiente={ambiente}
                                />
                            ))
                        }

                    </div>

                </div>
            </div>
        )
    }
}

export default Laudo