import api from '../../services/api'
import React from 'react';
import { Modal } from 'reactstrap';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import history from '../../routes/history'

class ModalUploadMidea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      files: [],
      uploading: false,
      progresso: false,
      porcentagem: 0,
      statusProgress: 'active',
      errorTela: false,
      verificarMovel: false
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const width = window.outerWidth
    if (width < 760) {
      this.setState({ verificarMovel: true })
    }
  }

  async enviarFotos(data) {
    api.post('/consultor/enviarfotos/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((response) => {
        console.log('enviou fotos', response.data)
        this.props.snackSuccess(true)
        this.toggle()
      })
      .catch((error) => {
        console.log('error', error.response);
        this.props.snackError(true)
        if (error.response.data.status === 403) {
          history.push({
            pathname: '/midias',
            state: {
              msg: error.response.data.msg,
            }
          })
        } else {
          alert('Erro: ' + error.response.data.message)
        }
        this.toggle()
      })
      .finally(() =>
        this.setState({
          uploading: false,
        })
      )
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleFile(e) {
    const arquivos = e.target.files
    this.setState({
      files: [...this.state.files, arquivos[0]]
    })
  }

  async handleUpload(idVisita, idProjeto) {
    if (this.state.verificarMovel === false) {
      await this.setState({
        uploading: true,
        progresso: true,
      })
      const files = this.state.files
      const data = new FormData();
      files.map((value, index) => {
        data.append('multipleFiles[]', value, value.name)
        data.append('id', idVisita)
        data.append('idProjeto', idProjeto)
      })
      this.enviarFotos(data)
      this.props.listarImoveis()

    } else {
      alert('Celulares ou Tablets são proibidos de enviar mídia')
    }

  }

  render() {
    return (
      <>
        <div className="button is-link is-small" onClick={this.toggle}>
          <p>{this.props.buttonLabel}</p>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal" style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>

          <div className="modal animated fadeIn faster">
            <div className="modal-background" />
            <div className="modal-card">
              <header toggle={this.toggle} className="modal-card-head">
                <p className="modal-card-title">Envio de Fotos</p>
                <button
                  className="button fechar-modal"
                  onClick={this.toggle}
                ><strong>fechar</strong></button>
              </header>
              <section className="modal-card-body">
                {this.state.progresso &&
                  <Progress
                    percent={this.state.porcentagem}
                    status={this.state.statusProgress} />
                }
                <form encType="multipart/form-data">
                  <label>Selecione as imagens: </label><br />
                  <div className='file has-name'>
                    <label className="file-label">
                      <input type="file" className="file-input" onChange={(e) => this.handleFile(e)} required />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                          Escolher o arquivo
                        </span>
                      </span>
                      <span className="file-name">
                        {this.state.files.length > 0 ? this.state.files[this.state.files.length - 1].name : 'Escolha o arquivo'}
                      </span>
                    </label>
                  </div>
                </form><br />
                <ul>
                  {this.state.files.length > 0 && (this.state.files.map((value) => (
                    <li>
                      {value.name}
                    </li>))
                  )}
                </ul>
              </section>
              <footer className="modal-card-foot">
                <button className={"button is-success " + (this.state.uploading ? "is-loading" : "")}
                  onClick={() => this.handleUpload(this.props.idVisita.id, this.props.idVisita.projetoId.id)}>Enviar</button>
                <button className="button"
                  onClick={this.toggle}>Cancelar</button>
              </footer>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default ModalUploadMidea;