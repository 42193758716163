import React from "react"
import { connect } from "react-redux"
import { Creators } from "../../store/ducks/app"
import api from "../../services/api"
import { format } from "date-fns"
import CustomSnackBar from "../../components/material/SnackBar"
import ModalConfirmEmail from "../modals/ModalConfirmEmail"

class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.handleActive = this.handleActive.bind(this)
        this.updateNotification = this.updateNotification.bind(this)
        this.updateRealTime = this.updateRealTime.bind(this)
    }

    state = {
        read: false,
        active: false,
        menuActive: false,
        totalNotification: 0,
        notifications: [],
        totalContasBancarias: 1,
        modalConfirm: false,
        linkEnviado: false,
        showNotification: false,
        notification: {}
    }

    componentDidMount() {
        this.updateNotification()
        this.verificarContaBancaria()
        if (localStorage.getItem("email_confirmado") !== "sim") {
            this.setState({ modalConfirm: true })
        }

        /*const fcmtoken = localStorage.getItem('fcmtoken')

        if ((fcmtoken !== 'undefined') && (fcmtoken !== '[object Object]') && (fcmtoken !== null)) {
            this.listenerFirebase(fcmtoken)
        }*/
    }

/*    listenerFirebase = (fcmtoken) => {
        if ((fcmtoken) && (fcmtoken !== '')) {
            messaging.onMessage((payload) => {
                this.updateNotification()
                console.log('mensagem~> ', payload)
                this.setState({
                    notification: payload.notification,
                    showNotification: true
                })
            })
            navigator.serviceWorker.addEventListener('message', (message) => {
                console.log('mensagem do firebase~> ', message)
            })
        }
    }*/

    notificationModal() {
        return (
            <div
                className="modal animated fadeIn faster"
                style={{ display: 'block' }}
            >
                <div className="modal-background" />
                <div className="modal-content" style={{ marginTop: 30 }}>
                    <div className="modal-body">
                        <article className="message is-warning">
                            <div className="message-header">
                                <p>{this.state.notification.title}</p>
                            </div>
                            <div className="message-body">
                                <p>{this.state.notification.body}</p>
                            </div>
                        </article>
                    </div>
                </div>
                <button
                    style={{ marginRight: 200 }}
                    onClick={() => this.fecharModal()}
                    className="modal-close is-large"
                    aria-label="close"
                />
            </div>
        )
    }

    fecharModal() {
        this.setState({
            notification: {},
            showNotification: false
        })
    }

    async updateNotification() {
        const idClient = localStorage.getItem("id")
        api.get(`/notificacao/notificacaoPessoa/${idClient}`)
            .then(response =>
                this.setState({
                    notifications: response.data.notificacoes
                })
            )
            .catch(error => console.log(error.response))
        api.get(`/notificacao/qtdNotificacaoNaoLidas/${idClient}`)
            .then(response =>
                this.setState({
                    totalNotification: response.data.quantidade
                })
            )
            .catch(error => console.log(error.response))
    }

    verificarContaBancaria() {
        api.get("/clientes/listaContasCliente").then(response =>
            this.setState({
                totalContasBancarias: response.data.contas.length
            })
        )
    }

    verificacaoEmail = () => {
        const verificacaoShow = localStorage.getItem('enviarConfirmacaoEmail')
        if (verificacaoShow === null) {
            return (
                <ModalConfirmEmail
                    open={this.state.modalConfirm}
                    close={() => this.setState({ modalConfirm: false })}
                    enviarLink={() => this.enviarLink()}
                />
            )
        }
    }

    handleMenu = () => {
        this.setState({
            menuActive: !this.state.menuActive
        })
    }

    updateRealTime = async () => {
        while (true) {
            setTimeout(() => {
                console.log("atualizando")
                this.updateNotification()
            }, 10000)
        }
    }

    async handleActive() {
        await this.setState(prevstate => ({
            active: !prevstate.active,
            read: true,
            totalNotification: 0
        }))
        console.log(this.state.read)
        if (this.state.read === true) {
            this.state.notifications.map(notification =>
                api
                    .put("/notificacao/updateNotificacao/" + notification.id, {})
                    .then(response => console.log(response.data))
                    .catch(error => console.log(error.response))
            )
        }
        await this.setState({
            read: false
        })
        console.log(this.state.read)
    }
    enviarLink() {
        api.get("/consultor/reenviarEmail/").then(response => {
            this.setState({ modalConfirm: false, linkEnviado: true })
        }).catch((err) => console.log(err.response))
    }
    render() {
        let classNav =
            `animated navbar navbar-primary is-tab has-shadow ` +
            (this.props.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")
        let tipoUsuario = localStorage.getItem("tipo")
        let tipoUsuarioString = ""
        if (tipoUsuario === "2") {
            tipoUsuarioString = "Indicador"
        } else if (tipoUsuario === "3") {
            tipoUsuarioString = "Consultor de Fotos"
        } else if (tipoUsuario === "4") {
            tipoUsuarioString = "Corretor"
        } else if (tipoUsuario === "5") {
            tipoUsuarioString = "Vistoriador"
        }

        return (
            <>
                {this.verificacaoEmail()}
                {(this.state.showNotification === true) ? this.notificationModal() : null}
                <div className="contentnav">
                    {this.state.totalContasBancarias < 1 && (
                        <CustomSnackBar
                            variant="warning"
                            message="Você Não Possui Conta Bancária Cadastrada. Cadastre sua Conta No Menu Ao Lado"
                        />
                    )}
                    {this.state.linkEnviado && (
                        <CustomSnackBar
                            variant="success"
                            message="Um link de confirmação foi enviado para seu email."
                        />
                    )}
                    <nav className={classNav}>
                        <div className="navbar-menu">
                            <i
                                onClick={this.props.changeHamburger}
                                role="button"
                                className="navbar-burger "
                                aria-label="menu"
                                aria-expanded="false"
                                style={{ margin: 0, color: "white" }}
                            >
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </i>

                            <span style={{ paddingTop: 14, fontWeight: "bold", paddingBottom: 14 }}>
                                <p style={{ padding: 0, margin: 0, fontSize: 25 }}>
                                    {this.props.title}
                                </p>
                                {window.innerWidth > 640 && (
                                    <strong style={{ color: "white" }}>
                                        {" "}
                                        Você está logado como: {tipoUsuarioString}
                                    </strong>
                                )}
                            </span>
                            <div className={"navbar-end"} style={{marginRight: 0}}>
                                <div className="dropdown is-active navbar-item">
                                    <div className="dropdown-trigger">
                                        <p
                                            onClick={() => this.handleActive()}
                                            className="navbar-item fas fa-bell"
                                            style={{ color: "white" }}
                                        ></p>
                                        {this.state.totalNotification > 0 && (
                                            <span
                                                onClick={() => this.handleActive()}
                                                className="badge"
                                            >
                                                {this.state.totalNotification}
                                            </span>
                                        )}
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu2" role="menu">
                                        {this.state.active && (
                                            <div className="dropdown-content scroll-notification">
                                                {this.state.notifications.map(
                                                    (notification, index) => (
                                                        <div
                                                            key={index}
                                                            style={
                                                                notification.lido === "não"
                                                                    ? { backgroundColor: "#e8e8e8" }
                                                                    : {}
                                                            }
                                                        >
                                                            <div className="dropdown-item">
                                                                <small>
                                                                    {format(
                                                                        notification.dataEnvio,
                                                                        "DD/MM/YYYY"
                                                                    )}
                                                                </small>
                                                                <p>
                                                                    <strong>
                                                                        {notification.title}
                                                                    </strong>
                                                                </p>
                                                                <i>{notification.mensagem}</i>
                                                            </div>
                                                            <hr className="dropdown-divider" />
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    token: state.auth.token,
    app: state.app
})

const mapActionCreators = {
    ...Creators
}

export default connect(
    mapStateToProps,
    mapActionCreators
)(NavBar)
