import React from "react"

import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import Loading from "../components/common/Loading"
import CustomSnackBar from '../components/material/SnackBar'
import api from "../services/api"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { setTimeout } from "timers";
registerLocale('pt', pt)


class Comissoes extends React.Component {

    constructor(props) {
        super(props);
        this.startHandleChange = this.startHandleChange.bind(this);
        this.endHandleChange = this.endHandleChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    state = {
        open: false,
        hamburguer: true,
        noCommission: false,
        startDate: new Date(),
        endDate: new Date(),
        activePage: 15,
        comissoes: [],
        totalComisaoPendente: 0,
        totalComissaoPaga: 0,
        totalComisao: 0,

    }

    startHandleChange(date) {
        this.setState({
            startDate: date
        });
    }

    endHandleChange(date) {
        this.setState({
            endDate: date
        });
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.state.comissoes === undefined ) {
                this.setState({
                    noCommission: true
                })
            }
        }, 2000);
    }

    componentWillMount() {
        if(window.innerWidth <= 640){
            this.setState({
                hamburguer: false,
            })
        }
        this.setState({
            open: true,
        })
        api.get(`/consultor/listMonetization/${localStorage.getItem('id')}`)
            .then((response) => {
                this.setState({
                    comissoes: response.data.comissoes,
                    totalComisaoPendente: response.data.totalComisaoPendente,
                    totalComissaoPaga: response.data.totalComissaoPaga,
                    totalComisao: response.data.totalComisao,
                    open: false,
                })
            }).catch((error) => {
                console.log(error)
                this.setState({
                    open: false,
                })
            })
    }

    filtroComissao() {
        const dataIni = format(this.state.startDate, 'YYYY-MM-DD')
        const dataFim = format(this.state.endDate, 'YYYY-MM-DD')
        setTimeout(() => {
            api.get(`/visitas/listMonetization/${localStorage.getItem('id')}
            ?dataIni=${dataIni}&dataFim=${dataFim}`)
                .then(async (response) => {
                    await this.setState({
                        comissoes: response.data.comissoes,
                        totalComisaoPendente: response.data.totalComisaoPendente,
                        totalComissaoPaga: response.data.totalComissaoPaga,
                        totalComisao: response.data.totalComisao,
                    })
                    console.log('fora', this.state.comissoes)
                }).catch((error) => console.log(error))
        }, 500)
        console.log(dataIni)
        console.log(dataFim)
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    render() {
        return (
            <div className="main">
                <Loading open={this.state.open} />
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Comissões" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.noCommission &&
                        <CustomSnackBar variant="warning"
                            message="Você ainda não possui comissões em nosso sistema" />}
                    <div className="card">
                        <div className="card-content">
                            <div className="card-title">
                                <h4>Filtro de Comissões por Período</h4>
                            </div>
                            <div className="columns">
                                <div className="column is-1">
                                    <p>Inicio: </p>
                                </div>
                                <div className="column is-2">
                                    <DatePicker className="input"
                                        selected={this.state.startDate}
                                        onChange={this.startHandleChange}
                                        dateFormat="dd/MM/yy"
                                    />
                                </div>
                                <div className="column is-1">
                                    <p>Fim: </p>
                                </div>
                                <div className="column is-2">
                                    <DatePicker className="input"
                                        selected={this.state.endDate}
                                        onChange={this.endHandleChange}
                                        dateFormat="dd/MM/yy"
                                    />
                                </div>
                                <div className="column is-2">
                                    <button
                                        onClick={() => this.filtroComissao()}
                                        className="button is-link">Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="card">
                        <div className="card-content rolagem-horizontal">
                            <div className="card-title">
                                <h4>Suas Comissões</h4>
                            </div>
                            <table className="table is-striped is-bordered">
                                <thead>
                                    <tr>
                                        <th>Imóvel</th>
                                        <th>Descrição</th>
                                        <th>Data de Confirmação</th>
                                        <th>Comissão</th>
                                        <th>Data de Pagamento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.comissoes !== undefined ?
                                        this.state.comissoes.map((comissao, index) => (
                                            <tr key={index}>
                                                <td>{comissao.idImovel.titulo}</td>
                                                <td>{comissao.descricao}</td>
                                                <td>{format(comissao.dataConfirmacao, 'DD/MM/YYYY')}</td>
                                                <td>R$ {comissao.comissao}</td>
                                                <td>{comissao.dataPagamento === null ? 'Não Informado' : format(comissao.dataPagamento, 'DD/MM/YYYY')}</td>
                                            </tr>
                                        )) : <h5 style={{marginTop: 20, color: '#e74c3c'}}>Você ainda não possui comissões</h5>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr/>
                    <div className="card">
                        <div className="card-content rolagem-horizontal">
                            <div className="card-title">
                                <h4>Ganhos Totais</h4>
                            </div>
                            <table className="table is-striped is-bordered">
                                <thead>
                                    <tr>
                                        <th>Comissão Pendente</th>
                                        <th>Comissão Paga</th>
                                        <th>Total de Comissão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <td style={{color: '#e74c3c'}}>{this.state.totalComisaoPendente === 0 ? 'Você ainda não possui comissões geradas em nosso sistema' : this.state.totalComisaoPendente}</td>
                                <td style={{color: '#e74c3c'}}>{this.state.totalComissaoPaga === 0 ? 'Você ainda não possui comissões geradas em nosso sistema' : this.state.totalComissaoPaga}</td>
                                <td style={{color: '#e74c3c'}}>{this.state.totalComisao === 0 ? 'Você ainda não possui comissões geradas em nosso sistema' : this.state.totalComisao}</td>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr />


                </div>
            </div>
        )
    }
}


export default Comissoes
