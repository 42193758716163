import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import Loading from "../components/common/Loading"

class Duvidas extends Component {

    state = {
        open: false,
        hamburguer: true,
        hue: null,
        indexPergunta: '',
        duvidas: [
            {
                pergunta: 'O que é o Plano B?',
                resposta: 'Um sistema que tem como proposta desburocratizar o processo de locação de Imóvel praticado pelas imobiliárias tradicionais'
            },
            {
                pergunta: 'Como funciona o processo de Envio de fotos?',
                resposta: 'Primeiro o sistema irá sortear o imóvel que foi aprovado em nosso sistema entre os Consultores de Imanges diponíveis em nosso sistema...'
            },
            {
                pergunta: 'Como eu recebo minha comissão?',
                resposta: 'Após o seu trabalho como Indicador/Consultor de Imagem/Corretor/Vistoriador for aprovado por um de nossos colaboradores, a comissão será gerada automaticamente'
            },
            {
                pergunta: 'Qual o tamanho máximo para envio de Fotos e Vídeos?',
                resposta: 'As Fotos e Vídeos devem ter no máximo 100Mb de tamanho'
            },
        ]
    }

    componentWillMount(){
        if(window.innerWidth <= 640){
            this.setState({
                hamburguer: false,
            })
        }
    }

    changeHamburger = () => {
        
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    handleCard = index => {
        this.setState(prevstate => ({
            hue: index,
            indexPergunta: index,
        }))
    }

    render() {
        return (
            <div className="main">
                <Loading open={this.state.open} />
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Dúvidas" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.duvidas.map((duvida, index) => (
                        <div key={index}>
                            <div className="card" style={{ cursor: 'pointer' }} onClick={() => this.handleCard(index)}>
                                <div className="card-content card-shadow">
                                    <div>
                                        <h3>{duvida.pergunta}</h3>
                                        {this.state.hue === index && this.state.indexPergunta === index && <p className="animated bounceInDown fast">{duvida.resposta}</p>}
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default Duvidas