import React, { useEffect } from "react"

export default  props => {
    useEffect(() => {
        if (props.payload.open === true) {
            setTimeout(() => {
                props.close()
            }, 5000)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.payload.open])
    if (props.payload.open === true) {
        return (
            <article className="alert-message animated bounceInDown">
                <div class={`notification is-${props.payload.type}`}>
                    <button onClick={() => props.close()} className="delete" aria-label="delete" />
                    {props.payload.text}
                </div>
            </article>
        )
    } else {
        return <span />
    }
}
