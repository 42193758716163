import api from "../../services/api"

export const Types = {
    FORM: "imovel/form",
    LOADING: "imovel/loading",
    FECTH: "imovel/fecth"
}

const INITIAL_STATE = {
    loading: false,
    data: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.LOADING:
            return { ...state, loading: action.loading }

        case Types.FECTH:
            return { ...state, data: action.payload.data }

        default:
            return state
    }
}

export const Creators = {
    loadding: loading => {
        return {
            type: Types.LOADING,
            loading
        }
    },
    fetch: data => dispatch => {
        dispatch({
            type: Types.LOADING,
            loading: true
        })

        api.get("/imoveis", data)
            .then(reponse => {
                dispatch({
                    type: Types.FECTH,
                    payload: { data: reponse.data }
                })
                dispatch({
                    type: Types.LOADING,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
}
