import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Creators } from "../store/ducks/atendimento"

import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import NewCardAtendimento from "../components/cards/NewCardAtendimento"
import ModalDetalhesProprietario from '../components/modals/ModalDetalhesProprietario'
import ModalObservacoes from "../components/modals/ModalObservacoes"
import PerguntasVisita from '../components/modals/PerguntasVisita'
import api from "../services/api"
import history from '../routes/history'
import Loading from "../components/common/Loading"

class Atendimentos extends React.Component {

    state = {
        hamburguer: true,
        atendimento: {},
        observacao: [],
        isLogged: localStorage.getItem('token'),
        indexDetalhe: -1,
        indexObservacao: -1,
        showModalObs: false,
        showModalDetalhe: false,
        idVisitaSelect: -1,
        idImovelSelect: -1,
        showModalPerguntas: false
    }

    componentWillMount() {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        this.props.fetch()
    }

    observacoes(id, atendimentoAtual = null, index) {
        console.log('id~>', id)
        console.log('atendimento~> ', atendimentoAtual)
        console.log('index~>', index)


        api.get(`/visitas/listComments?idVisita=${id}`)
            .then((response) => {
                const obsList = response.data.list
                console.log('resposta~> ', response)
                console.log('atendimento atual~>', atendimentoAtual)
                this.setState({
                    observacao: obsList,
                })
                if (atendimentoAtual !== null) {
                    this.setState({ atendimento: atendimentoAtual })
                }
                if (index > -1) {
                    this.setState({ showModalObs: true, indexObservacao: index })
                }
            }).catch((response) => {
                console.log(response.response)
            })
    }

    criarObservacao(textoObs, idVisita) {
        console.log('idvisita~>', idVisita)
        console.log('criando obs', textoObs)
        api.post('/visitas/createComments', {
            idVisita: parseInt(idVisita),
            texto: textoObs
        })
            .then(response => {
                console.log(response.data)
                this.observacoes(idVisita, null, -1)
                this.props.setForm(false);
            })
            .catch(response => {
                console.log(response.response);
            })
    }

    iniciaAtendimento(atendimento, idVisita) {
        this.props.modal("atendimento", true)
        this.setState({ atendimento })
        api.put('/visitas/inVisit', {
            idVisita: parseInt(idVisita)
        })
            .then((response) => {
                console.log('visitas/invisit ', response.data);
                this.props.fetch()
                localStorage.setItem('visitaIniciada', 'sim')
            })
            .catch((response) => {
                console.log('erro invisit', response);
            })
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    confirmaAtendimento(type, idVisita) {
        if (type === 'aceitar') {
            api.put('/visitas/acceptedVisit', {
                visita: parseInt(idVisita)
            })
                .then((response) => {
                    console.log('aceita ', response.data)
                    this.props.fetch()
                    localStorage.setItem('visitaEncerrada', 'nao')
                    localStorage.setItem('visitaIniciada', 'nao')
                })
                .catch((response) => {
                    console.log('erro aceit', response.data)
                })
        } else if (type === 'recusar') {
            api.put('/visitas/refuseVisit', {
                visita: parseInt(idVisita)
            })
                .then((response) => {
                    console.log('recusa', response.data)
                    this.props.fetch()
                })
                .catch((response) => {
                    console.log('erro recusa', response.data)
                })
        } else {
            api.put('/visitas/confirmVisit', {
                idVisita: parseInt(idVisita)
            })
                .then((response) => {
                    console.log('confirma ', response.data)
                    this.props.fetch()
                })
                .catch((response) => {
                    console.log('erro conf', response.data)
                })
        }
    }

    encerrarAtendimento = (idVisita, idImovel) => {
        this.setState({
            showModalPerguntas: true,
            idVisitaSelect: idVisita,
            idImovelSelect: idImovel
        })
    }

    finalizarAtendimento = idVisita => {
        api.put('/visitas/requestFinalizationVisit', {
            idVisita
        }).then((response) => {
            console.log('encerra visita')
            this.props.fetch()
            localStorage.setItem('visitaIniciada', 'nao')
            localStorage.setItem('visitaEncerrada', 'sim')
        }).catch((error) => {
            console.log('encerra atendi', error)
        })
    }

    detalhesState = (index) => {
        console.log('index clicado~> ', index)
        this.setState({
            indexDetalhe: index,
            showModalDetalhe: true
        })
    }

    fecharModalDetalhes = () => {
        this.setState({ showModalDetalhe: false })
    }

    fecharModalObs = () => {
        this.setState({ showModalObs: false, observacao: [] })
    }

    fecharQuestionario = () => {
        this.setState({ showModalPerguntas: false, idVisitaSelect: -1 })
    }

    render() {
        if (this.state.isLogged === null) {
            history.push('/consultor/login')
        }
        if (localStorage.getItem('tipo') === '3') {
            history.push('/midias')
        }
        if (localStorage.getItem('tipo') === '2') {
            history.push('/indicacoes')
        }
        if (localStorage.getItem('tipo') === '5') {
            history.push('/vistorias')
        }

        console.log('dados~> ', this.props.atendimento.data)
        return (
            <div className="main">
                {((this.state.showModalObs === true) && (this.state.indexObservacao > -1)) && (
                        <ModalObservacoes
                            item={this.props.atendimento.data[this.state.indexObservacao]}
                            observacoes={this.state.observacao}
                            close={() => this.fecharModalObs()}
                            showmodalObs={this.state.showModalObs}
                            criarObservacao={(textoObs, idObs) => this.criarObservacao(textoObs, idObs)}
                        />
                )}

                {((this.state.showModalDetalhe === true) && (this.state.indexDetalhe > -1)) && (
                    <ModalDetalhesProprietario
                        idImovel={this.props.atendimento.data[this.state.indexDetalhe].idImovel.id}
                        showModalDetalhe={this.state.showModalDetalhe}
                        close={() => this.fecharModalDetalhes()}
                    />
                )}

                {((this.state.showModalPerguntas === true) && (this.state.idVisitaSelect > -1)) && (
                        <PerguntasVisita
                            idVisita={this.state.idVisitaSelect}
                            idImovel={this.state.idImovelSelect}
                            showModal={this.state.showModalPerguntas}
                            finalizarAtendimento={(idVisita) => this.finalizarAtendimento(idVisita)}
                            close={() => this.fecharQuestionario()}
                        />
                )}

                <Loading open={this.props.app.loading} />
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Atendimentos" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div style={{ marginBottom: 50 }} className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {(this.props.atendimento.data.length > 0) ? this.props.atendimento.data.slice(0).reverse().map((atendimento, index) => {
                        return (
                            <div className="columns is-multiline" key={`atendimento-${atendimento.id}`}>
                                <NewCardAtendimento
                                    atendimento={atendimento}
                                    //rusbe={this.props.testeCoisado}
                                    detalhe={(index) => this.detalhesState(index)}
                                    index={index}
                                    observacoes={(id, atendimentoAtual, index) => this.observacoes(id, atendimentoAtual, index)}
                                    encerrarAtendimento={(id, idImovel) => this.encerrarAtendimento(id, idImovel)}
                                    iniciaAtendimento={(atendimento, idVisita) => this.iniciaAtendimento(atendimento, idVisita)}
                                    confirmaAtendimento={(type, idClient) => this.confirmaAtendimento(type, idClient)}
                                />
                            </div>
                        )
                    }) : (
                            <div style={{ height: '100%', width: '100%' }}>
                                <h1 className="center-progress" style={{textAlign: 'center'}}>Sem demandas registradas</h1>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    atendimento: state.atendimento,
    app: state.app
})

const mapActionsCreators = {
    ...Creators
}

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsCreators
    )(Atendimentos)
)
