import React, { Component } from 'react'
import logo from '../images/logo_color.png'
import api from "../services/api"
import history from '../routes/history'


class Confirmado extends Component{

    componentDidMount(){
        var queryString = window.location.href ? window.location.href.split('?token=')[1] : window.location.search.slice(1);
        api.get(`consultor/confirmarEmail/${queryString}`)
        .then(response => console.log(response.data))
        .catch(error => console.log(error))
    }

    loginRedirect(){
        history.push('/consultor/login')
    }

    render(){
        
        return(
            <div className="content-login">
                <div className="card container-login">
                    <div className="card-content card-login">
                        
                        <img className="img-login" src={logo} alt="" />
                        <h4 className="title is-5">Obrigado</h4>
                        <p style={{textAlign: 'center'}}>Seu e-mail foi confirmado com sucesso. <br/> Clique no botão abaixo e faça Login</p>
                        
                        <button onClick={() => this.loginRedirect()} className="btn-login">Fazer Login</button>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Confirmado