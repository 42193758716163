import React from "react"
import { connect } from "react-redux"
import { Creators } from "../../store/ducks/app"

import MenuItem from "./menuItem"
import Message from "../common/Message"

const menus = [
    { link: "/atendimentos", label: "Atendimentos" },
    { link: "/indicacoes", label: "Fazer Indicação" },
    { link: "/midias", label: "Mídias" },
    { link: "/vistorias", label: "Vistorias" },
    { link: "/meusdados", label: "Meus Dados" },
    { link: "/dadosbancarios", label: "Dados Bancários" },
    { link: "/comissoes", label: "Comissões" },
    { link: "/duvidas", label: "Dúvidas" },
    { link: "/parceiros", label: "Parceiros" },
    { link: "/sair", label: "Sair" }
]

const Sidebar = props => {
    const positionLeft = props.app.sidebar === true ? 0 : -300
    const userName = localStorage.getItem("nome")
    const userTipo = localStorage.getItem("tipo")
    let tipoUsuario = localStorage.getItem("tipo")
    let tipoUsuarioString = ""
    if (tipoUsuario === "2") {
        tipoUsuarioString = "Indicador"
    } else if (tipoUsuario === "3") {
        tipoUsuarioString = "Consultor de Fotos"
    } else if (tipoUsuario === "4") {
        tipoUsuarioString = "Corretor"
    } else if (tipoUsuario === "5") {
        tipoUsuarioString = "Vistoriador"
    }
    return (
        <div
            className={
                "animated sidebar " + (props.hamburguer ? "bounceInLeft fast" : "is-hidden-mobile")
            }
            style={{ left: positionLeft }}
        >
            <Message payload={props.app.message} close={props.closeMessage} />
            <div className="logo-name">
                <div className="logo" />
                <strong>Plano B</strong>
                <span style={{ fontSize: 12 }}>{userName}</span>
                {window.innerWidth <= 640 && (
                    <span style={{ fontSize: 12, textAlign: "center", marginTop: 5 }}>
                        {" "}
                        Você está logado como: {tipoUsuarioString}
                    </span>
                )}
            </div>
            <ul>
                {menus.map((menu, i) => {
                    switch (userTipo) {
                        case "2":
                            if (
                                menu.label === "Mídias" ||
                                menu.label === "Vistorias" ||
                                menu.label === "Atendimentos"
                            ) {
                                return false
                            } else {
                                return <MenuItem key={`menu_${i}`} {...props} menu={menu} />
                            }
                        case "4":
                            if (menu.label === "Mídias" || menu.label === "Vistorias") {
                                return false
                            } else {
                                return <MenuItem key={`menu_${i}`} {...props} menu={menu} />
                            }
                        case "3":
                            if (menu.label === "Atendimentos" || menu.label === "Vistorias") {
                                return false
                            } else {
                                return <MenuItem key={`menu_${i}`} {...props} menu={menu} />
                            }
                        case "5":
                            if (menu.label === "Mídias" || menu.label === "Atendimentos") {
                                return false
                            } else {
                                return <MenuItem key={`menu_${i}`} {...props} menu={menu} />
                            }
                        default:
                            return <MenuItem key={`menu_${i}`} {...props} menu={menu} />
                    }
                })}
            </ul>
        </div>
    )
}

const mapStateToProps = state => ({
    app: state.app
})
const mapDispatchToProps = {
    ...Creators
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
