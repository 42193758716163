import React, { Component } from 'react'
import If from "../common/IF"
import { format } from 'date-fns';
import { Link } from "react-router-dom"



class VistoriaCard extends Component {

    constructor(props) {
        super(props);
        this.aceitar = this.aceitar.bind(this);
    }

    state = {
        iniciada: false,
        finalizada: false,
        aceite: false,
        aceite2: false
    }

    aceitar = async () => {
        this.setState({ aceite: true, aceite2: true })
        console.log("state aceitar no inicio", this.state)
        await this.props.aceitarVistoria(this.props.vistoria.Id)

        console.log("state aceitar no final", this.state)
    }

    recusar = async () => {
        this.setState({ aceite: true })
        await this.props.recusarVistoria(this.props.vistoria.Id)
        this.setState({ aceite: false })
    }

    iniciar = () => {
        this.props.iniciarVistoria(this.props.vistoria.Id)
        this.setState({ iniciada: true })
    }

    confirmar = () => {
        this.props.confirmarVistoria(this.props.vistoria.Id)
        this.setState({ finalizada: true })
    }

    cancelar = () => {
        this.props.cancelarVistoria(this.props.vistoria.Id)
    }

    solicitar = () => {
        this.props.solicitarConfirmacaoVistoria(this.props.vistoria.Id)

    }


    dataFormatada = (data, hora) => {
        const date = `${data} ${hora}`
        return format(date, 'DD/MM/YYYY - HH:mm:ss')
    }

    render() {


        return (
            <div className="imovelItem" style={{height: '100%'}} >
                <div className="imovelImage" style={{ textAlign: "center" }}>
                    {console.log(this.props.vistoria.imagem)}


                    {this.props.vistoria.imagem !== null &&
                        <img src={this.props.vistoria.imagem.link} alt="Teste de Layout" />
                    }

                    {this.props.vistoria.imagem === null &&
                        <img src="https://planob.classeloc.com.br/images/semfoto.png" style={{ height: 175 }} alt="Teste de Layout" />
                    }

                </div>
                <div className="imovelInfo" >
                    <div className="imovelInfoEndereco">
                        <If test={this.props.vistoria.Consultor.Resposta === 'PENDENTE'}>
                            <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                            <p><strong>{this.props.vistoria.Status}</strong></p>
                            <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                            <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>

                                {this.state.aceite === true &&
                                    <button className="button is-small" disabled>Processando...</button>
                                }

                                {this.state.aceite === false &&
                                    <React.Fragment>
                                        <button onClick={this.aceitar} className="button is-success is-small">Aceitar</button>
                                        <button onClick={this.recusar} className="button is-danger is-small">Recusar</button>
                                    </React.Fragment>
                                }


                                <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                            </div>
                        </If>
                        <If test={this.props.vistoria.Consultor.Resposta === 'ACEITA'}>
                            <If test={this.props.vistoria.Status === 'Agendado'}>
                                <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                                <p><strong>{this.props.vistoria.Status}</strong></p>
                                <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                                <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>

                                    <If test={this.state.iniciada === true}>
                                        <button
                                            onClick={this.iniciar}
                                            className="button is-success is-small btnPrimary" disabled>Aguardando Resposta</button>
                                        <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                    </If>

                                    <If test={this.state.iniciada === false}>
                                        <button
                                            onClick={this.iniciar}
                                            className="button is-success is-small btnPrimary">Iniciar Vistoria</button>

                                        <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                    </If>


                                </div>
                            </If>

                            <If test={this.props.vistoria.Status === 'Pendente'}>

                                <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                                <p><strong>{this.props.vistoria.Status}</strong></p>
                                <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>

                                <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>
                                    <button onClick={this.iniciar} className="button is-success is-small btnPrimary" disabled>Aguardando Resposta</button>
                                    <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                </div>
                            </If>

                            <If test={this.props.vistoria.Status === 'Em vistoria'}>
                                <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                                <p><strong>{this.props.vistoria.Status}</strong></p>
                                <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                                <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>



                                    <If test={this.props.vistoria.Laudo === 'nao'}>
                                        <p className="has-text-danger is-size-7">Obs: Para poder encerrar a vistoria envie seu laudo. </p>
                                        <Link to={"/laudo/" + this.props.vistoria.Id} className="button is-small">Laudo</Link>
                                        <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                    </If>



                                    <If test={this.props.vistoria.Laudo === 'sim'}>

                                        <If test={this.state.finalizada === true}>
                                            <button className="button is-small" disabled>Processando...</button>
                                        </If>


                                        <If test={this.state.finalizada === false}>
                                            <button className="button is-success is-small btnPrimary" onClick={this.confirmar}>Finalizar Vistoria</button>
                                            <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                        </If>

                                    </If>



                                </div>
                            </If>
                            <If test={this.props.vistoria.Status === 'Confirmado'}>
                                <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                                <p><strong>{this.props.vistoria.Status}</strong></p>
                                <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                                <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>
                                    <button
                                        className="button is-small" disabled>Encerrado</button>
                                    <Link to={"/vistoriadetalhes/" + this.props.vistoria.Id} style={{ marginLeft: 5 }} className="button is-info is-small btnInfo">Detalhes</Link>
                                </div>
                            </If>
                            <If test={this.props.vistoria.Status === 'Cancelado'}>
                                <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                                <p><strong>{this.props.vistoria.Status}</strong></p>
                                <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                                <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>
                                    <button
                                        className="button is-success is-small btnsuccess" disabled>Encerrado</button>
                                </div>
                            </If>
                        </If>
                        <If test={this.props.vistoria.Consultor.Resposta === 'RECUSADO'}>
                            <p><strong>ID:{this.props.vistoria.Id}</strong></p>
                            <p><strong>{this.props.vistoria.Status}</strong></p>
                            <p><small>{this.dataFormatada(this.props.vistoria.Data, this.props.vistoria.Hora)}</small></p>
                            <div className="imovelInfoAcoes" style={{ marginTop: 10 }}>
                                <button
                                    className="button is-danger is-small" disabled>Recusado</button>
                            </div>
                        </If>
                    </div>

                </div>
            </div>
        )
    }
}

export default VistoriaCard