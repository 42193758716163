import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Loading from '../components/common/Loading'

import { Creators } from "../store/ducks/atendimento"
import CustomSnackBar from '../components/material/SnackBar'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import InputMask from 'react-input-mask'
import Input from '../components/forms/Input'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import history from '../routes/history'

class Indicacoes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorIndicacao: false,
            hamburguer: true,
            buttonLoading: false,
            uploadIndicacao: false,
            proprietario: '',
            email: '',
            telefone: '',
            endereco: '',
            anonimo: 'não',
            indicacoes: [],
            open: false,
            isLogged: localStorage.getItem('token'),
            error: false,
            message: ''
        };
    }

    async listarIndicacoes() {
        this.setState({ open: true })
        api.get('/indicacoes/listaindicacoes')
            .then((response) => {
                console.log('ind', response.data.indicacoes)
                this.setState({
                    indicacoes: response.data.indicacoes.reverse(),
                    open: false
                })
            })
            .catch((error) => console.log('err get', error.response))
    }

    componentDidMount() {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        this.listarIndicacoes()
    }

    indicarImovel() {
        this.setState({
            buttonLoading: true,
            open: true
        })

        if (this.state.proprietario.length > 0 && this.state.endereco.length > 0) {
            if (this.state.email.length > 0 || this.state.telefone.length > 0) {
                api.post('/imoveis/indicar', {
                    proprietario: this.state.proprietario,
                    email: this.state.email,
                    telefone: this.state.telefone,
                    endereco: this.state.endereco,
                    anonimo: this.state.anonimo
                }).then((response) => {
                    console.log('resultado ', response.data)
                    this.listarIndicacoes()
                    this.setState({
                        open: false,
                        uploadIndicacao: true,
                        proprietario: '',
                        email: '',
                        telefone: '',
                        endereco: '',
                        anonimo: 'não',
                    })
                }).catch((err) => {
                    console.log('err ', err.response)
                    this.setState({
                        open: false,
                        error: true,
                        message: err.response.data.message
                    })
                    setTimeout(() => {
                        this.setState({
                            error: false,
                            message: '',
                        })
                    }, 3000);
                }).finally(
                    () => {
                        this.setState(prevstate => ({
                            buttonLoading: !prevstate.buttonLoading,
                            uploadIndicacao: false
                        }))
                    })
            } else {
                this.setState({
                    buttonLoading: false,
                    errorIndicacao: true,
                    open: false
                })
            }
        } else {
            this.setState({
                buttonLoading: false,
                errorIndicacao: true,
                open: false
            })
        }
        setTimeout(() => {
            this.setState({
                errorIndicacao: false,
                open: false
            })
        }, 3000);
    }

    anonimo = (value) => {
        if (value === true) {
            this.setState({
                anonimo: 'sim'
            })
        } else {
            this.setState({
                anonimo: 'não'
            })
        }
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    render() {
        console.log(localStorage.getItem("email_confirmado"))
        if (this.state.isLogged === null) {
            history.push('/consultor/login')
        }
        return (
            <div className="main">
                <Loading open={this.state.open} />
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Comissões" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.uploadIndicacao &&
                        <CustomSnackBar variant="success"
                            message="Indicação cadastrada com sucesso" />}
                    {this.state.errorIndicacao &&
                        <CustomSnackBar variant="error"
                            message="Preencha todos os campos corretamente" />}

                    {this.state.error === true &&
                        <CustomSnackBar variant="error" message={this.state.message} />
                    }

                    <div className="card">
                        <div className="card-content">
                            <div>
                                <h2>Faça a sua Indicação</h2>
                                <p style={{ fontSize: 18 }}>
                                    No PlanoB sua indicação vale <strong>dinheiro</strong>. Indique um imóvel informando o <strong>Nome, Endereço, Telefone e E-mail (opcional)</strong> nos
                                    campos abaixo que vamos entrar em contato com o Indicado. Caso o Indicado aceite cadastrar seu imóvel em nosso sistema
                                        você recebe uma <strong>comissão em dinheiro</strong> e mais <strong>5%</strong> do valor do aluguel do imóvel caso este seja <strong>alugado através de nossa
                                        plataforma</strong>.
                                    </p>
                            </div>

                            {localStorage.getItem("email_confirmado") === "sim" ?
                                <>
                                    <h4 style={{ marginTop: 30 }}>Preencha os campos abaixo para fazer uma indicação <span role="img" aria-label="sorriso maroto">😁</span></h4>
                                    <div className="columns" style={{ marginTop: 0 }}>
                                        <div className="column">
                                            <Input type="text"
                                                placeholder="Nome"
                                                icon="fas fa-user"
                                                chave="proprietario"
                                                value={this.state.proprietario}
                                                onChange={(e) => this.setState({ proprietario: e.target.value })} />
                                        </div>
                                        <div className="column">
                                            <Input type="text"
                                                placeholder="Endereço"
                                                icon="fas fa-home"
                                                chave="endereco"
                                                value={this.state.endereco}
                                                onChange={(e) => this.setState({ endereco: e.target.value })} />
                                        </div>

                                    </div>
                                    <div className="columns" style={{ marginTop: 0 }}>
                                        <div className="column">
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={this.state.telefone}
                                                onChange={(e) => this.setState({ telefone: e.target.value })}>
                                                {() =>
                                                    <div className="field">
                                                        <div className="control has-icons-left has-icons-right">
                                                            <input className="input" placeholder="Telefone" />
                                                            <span className="icon is-small is-left">
                                                                <i className="fas fa-mobile-alt"></i>
                                                            </span>
                                                        </div>
                                                    </div>}
                                            </InputMask>
                                        </div>
                                        <div className="column">
                                            <Input type="text"
                                                placeholder="E-mail"
                                                icon="fas fa-at"
                                                chave="email"
                                                value={this.state.email}
                                                onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="column center-check">
                                            <label class="checkbox">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => this.anonimo(e.target.checked)}
                                                    checked={this.state.anonimo === 'não' ? false : true}
                                                />
                                                <span className="check-left">Indicação anônima</span>
                                            </label>
                                        </div>
                                        <div className="column">
                                            <button
                                                className={"button is-success " + (this.state.buttonLoading ? "is-loading" : "")}
                                                onClick={() => this.indicarImovel()}
                                            >Fazer Indicação</button>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                            }

                        </div>
                    </div>
                    <hr />
                    <div className="card">
                        <div className="card-content rolagem-horizontal">
                            <div className="card-title">
                                <h2>Minhas Indicações</h2>
                            </div>
                            <table className="table is-striped is-bordered">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Telefone</th>
                                        <th>Status</th>
                                        <th>Comissão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.indicacoes.length > 0 ? this.state.indicacoes.map((indicacao, index) => (
                                        <tr key={index}>
                                            <td>{indicacao.proprietario}</td>
                                            <td>{indicacao.email === '' ? 'Não Informado' : indicacao.email}</td>
                                            <td>{indicacao.telefone === '' ? 'Não Informado' : indicacao.telefone}</td>
                                            <td>{indicacao.status}</td>
                                            <td>{indicacao.comissao}</td>
                                        </tr>
                                    )) : <h4 style={{ marginTop: 20, color: '#e74c3c' }}>Você não possui indicações cadastradas em nosso sistema</h4>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    atendimento: state.atendimento,
    app: state.app
})

const mapActionsCreators = {
    ...Creators
}

export default withRouter(
    connect(
        mapStateToProps,
        mapActionsCreators
    )(Indicacoes)
)
