import api from "./api"


export const get = () => {
    return new Promise(resolve => {
        var idClient = localStorage.getItem('id');
        api.get(`/visitas/listVisitConsultant/${idClient}`)
        .then(response => {
            console.log('consulta', response)
            setTimeout(() => {
                resolve({ data: response.data.atendimentos })
            }, 200)
        })
        .catch(err => {
            console.log('erro consulta', err);
        })
        // setTimeout(() => {
        //     resolve({ data: atendimentos })
        // }, 200)
    })
}

export const create = data => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                data: "08/05/2019",
                observacao: `Nova observacao criada no sistema.`
            })
        }, 700)
    })
}
