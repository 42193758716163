export const Types = {
    PERMISSAO: `consultor/permissao`
}

const INITIAL_STATE = {
    permitidos: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export const Creators = {}
