import React from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Input from '../components/forms/Input'
import logo from '../images/logo_color.png'
import '../styles/login.css';
import api from "../services/api"
import history from '../routes/history'
import InputMask from 'react-input-mask';
import CustomizedSnackbars from '../components/material/SnackBar'
import Chip from '@material-ui/core/Chip'
import If from "../components/common/IF"
import { CircularProgress } from '@material-ui/core';
import Loading from '../components/common/Loading'
import { color } from '../core/Colors'
import axios from "axios"
import ModalTermosUso from '../components/modals/ModalTermosUso'

class Cadastro extends React.Component {

  state = {
    showModal: false,
    openProgressBar: false,
    carregando: false,
    isCorretor: false,
    erroCorretor: false,
    fcmtoken: '',
    erroTipo: false,
    loadCep: false,
    cepFail: false,
    tipoIndicador: false,
    tipoVistoriador: false,
    tipoConsultorFoto: false,
    tipoCorretor: false,
    massa: [
      { label: 'Indicador', estilo: false, tipo: 2 },
      { label: 'Consultor de Imagem', estilo: false, tipo: 3 },
      { label: 'Vistoriador', estilo: false, tipo: 5 },
      { label: 'Corretor', estilo: false, tipo: 4 },
    ],
    labelChip: [
      { label: 'Indicador', estilo: false },
      { label: 'Consultor de Imagem', estilo: false },
      { label: 'Vistoriador', estilo: false },
      { label: 'Corretor', estilo: false },
    ],
    estiloOn: { backgroundColor: color.primary, color: 'white', margin: 5, marginBottom: 20, marginTop: 10 },
    estiloOff: { backgroundColor: '#cccc', color: 'black', margin: 5, marginBottom: 20, marginTop: 10 },
    showErrorCpf: false,
    invalidCpf: false,
    step: false,
    step2: false,
    setup: 1,
    stepPessoa: false,
    stepEndereco: false,
    stepDocumento: false,
    disabled: false,
    disabledBairro: false,
    erroCadastro: false,
    cpfInvalido: false,
    listaEstados: [],
    listaMunicipio: [],
    listaBairros: [],
    estado: '',
    municipio: '',
    nome: '',
    email: '',
    fone: '',
    senha: '',
    senhaVazia: false,
    documento: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    creci: '',
    ufcreci: '',
    cnh: '',
    dut: '',
    especificacaoCamera: '',
    erroEspacoBranco: '',
    IdEstado: 9,
    IdMunicipio: 879,
    tipo: [],
    message: "Seu cadastro foi efetuado com sucesso. Em breve vamos liberar o seu acesso",
    erroGenerico: false,
    messageError: '',
    documentoSelfie: '',
    documentoCnh: '',
    documentoResidencia: '',
    documentoDut: '',
    documentoFotoEquipamento: '',
    documentoEspecificacao: '',
    documentoCreci: '',
    documentoDiploma: '',
    uploading: '',
    progresso: '',
    porcentagem: '',
    progressBar: '',
    tipoCliente: '',
    idConsultor: '',
    listaDocumentosCompletos: [],
    errorMsg: '',
    containerBorder: { borderWidth: 2, borderRadius: 5, borderStyle: 'solid', borderColor: '#ccc', marginBottom: 10 },
    documentoFotoEscolhido: 'rg',
    documentoRGAtras: '',
    documentoRGFrente: '',
  }

  testaCPF(strCPF) {
    let Soma = 0;
    let Resto = 0;

    if (strCPF === "00000000000") {
      console.log('cpf invalido')
      this.cpfState(false)
      return false
    }

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      console.log('cpf invalido')
      this.cpfState(false)
      return false
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      console.log('cpf invalido')
      this.cpfState(false)
      return false
    }

    console.log('cpf valido')
    this.cpfState(true)
    return true;
  }

  handleValidarCpf = event => {
    this.setState({ showErrorCpf: true, documento: event.target.value })
    let cpf = event.target.value.replace('.', "").replace('-', "").replace('.', "")
    if (cpf.length > 0) {
      if (this.testaCPF(cpf)) {

        this.setState({
          invalidCpf: false
        })
      } else {

        this.setState({
          invalidCpf: true
        })
      }
    }
  }

  autoCep = e => {
    this.setState({ cep: e.target.value })
    let cepFormated = e.target.value.replace(/_/g, '').replace('-', '')
    if (cepFormated.length >= 8) {
      this.setState({
        loadCep: true
      })
      axios.get('https://viacep.com.br/ws/' + cepFormated + '/json')
        .then(async (response) => {
          console.log('cep~> ', response)
          this.setState({
            logradouro: response.data.logradouro,
            estado: response.data.uf,
            municipio: response.data.localidade,
            bairro: response.data.bairro,
            disabled: true,
            disabledBairro: true,
            loadCep: false
          })
          for (let estado of this.state.listaEstados) {
            if (estado.uf === this.state.estado) {
              await this.pegarCidade(estado.id)
            }
          }
          setTimeout(() => {
            for (let municipio of this.state.listaMunicipio) {
              if (municipio.descricao === this.state.municipio) {
                this.pegarBairro(municipio.id)
              }
            }
          }, 2000);
        })
        .catch((err) => {
          console.log('erro cep~> ', err)
          this.setState({
            loadCep: false,
            cepFail: true
          })
        })
    }
  }

  componentDidMount() {
    const fcmtoken = localStorage.getItem('fcmtoken')
    this.setState({ fcmtoken })

    this.setState({ carregando: true })
    if (window.innerWidth <= 640) {
      this.setState({
        hamburguer: false,
      })
    }
    api.get('/estado')
      .then(response =>
        this.setState({
          listaEstados: response.data,
          carregando: false
        })
      ).catch((err) => {
        console.log('err ', err.response)
        this.setState({ carregando: false })
      })
  }

  pegarBairro(idMunicipio) {
    this.setState({ carregando: true })
    api.get('/bairro?idMunicipio=' + idMunicipio)
      .then(response =>
        this.setState({
          disabledBairro: true,
          listaBairros: response.data,
          IdMunicipio: idMunicipio,
          carregando: false
        })
      )
      .catch(err => {
        console.log('err', err.response)
        this.setState({
          carregando: false,
          idMunicipio
        })
      })
  }

  pegarCidade(idEstado) {
    this.setState({ carregando: true })
    api.get(`/municipio?idEstado=${idEstado}`)
      .then(response =>
        this.setState({
          listaMunicipio: response.data,
          disabled: true,
          carregando: false
        })
      )
      .catch((err) => {
        console.log('err ', err.response)
        this.setState({ carregando: false })
      })
    this.setState({ IdEstado: idEstado })
  }

  setup(estado) {
    switch (estado) {
      case 1:
        this.setState({
          setup: 1,
          stepPessoa: true,
          stepEndereco: false,
          stepDocumento: false,
        })
        break
      case 2:
        let coisa = []

        for (let i = 0; i < this.state.massa.length; i++) {
          if (this.state.massa[i].estilo) {
            coisa.push(this.state.massa[i].tipo)
          }
        }

        if (coisa.length < 1) {
          this.setState({
            erroTipo: true,
          })

          setTimeout(() => {
            this.setState({
              erroTipo: false
            })
          }, 3000)
        }
        else {
          console.log('tipo~> ', coisa)
          this.setState({
            setup: 2,
            tipo: coisa,
            stepPessoa: false,
            stepEndereco: true,
            stepDocumento: false,
          })
        }
        break
      case 3:
        this.setState({
          setup: 3,
          stepPessoa: false,
          stepEndereco: false,
          stepDocumento: true,
        })
        break
      default:
        this.setState({
          setup: 1,
          stepPessoa: true,
          stepEndereco: false,
          stepDocumento: false,
        })
    }
  }

  cpfState(test) {
    if (test === true) {
      this.setState({
        cpfInvalido: false
      })
    }

    if (test === false) {
      this.setState({
        cpfInvalido: true
      })

      setTimeout(() => {
        this.setState({
          cpfInvalido: false
        })
      }, 2000)
    }
  }

  async selecionarTipoConsultor(i, index) {
    if (i.label === 'Indicador') {
      await this.setState(prevState => ({
        tipoIndicador: !prevState.tipoIndicador
      }))
      let beforeInd = this.state.massa
      let listaInd = { label: 'Indicador', estilo: this.state.tipoIndicador, tipo: 2 }
      beforeInd[index] = listaInd
      await this.setState({
        massa: beforeInd,
      })
    } else if (i.label === 'Consultor de Imagem') {
      await this.setState(prevState => ({
        tipoConsultorFoto: !prevState.tipoConsultorFoto
      }))
      let beforeInd = this.state.massa
      let listaInd = { label: 'Consultor de Imagem', estilo: this.state.tipoConsultorFoto, tipo: 3 }
      beforeInd[index] = listaInd
      await this.setState({
        massa: beforeInd
      })
    } else if (i.label === 'Vistoriador') {
      await this.setState(prevState => ({
        tipoVistoriador: !prevState.tipoVistoriador
      }))
      let beforeInd = this.state.massa
      let listaInd = { label: 'Vistoriador', estilo: this.state.tipoVistoriador, tipo: 5 }
      beforeInd[index] = listaInd
      await this.setState({
        massa: beforeInd
      })
    } else if (i.label === 'Corretor') {
      await this.setState(prevState => ({
        tipoCorretor: !prevState.tipoCorretor,
        isCorretor: !prevState.isCorretor
      }))
      let beforeInd = this.state.massa
      let listaInd = { label: 'Corretor', estilo: this.state.tipoCorretor, tipo: 4 }
      beforeInd[index] = listaInd
      await this.setState({
        massa: beforeInd,
      })
    }
  }

  async enviarDocumentacao(data) {
    new Promise((resolve, reject) => {
      if ((data.get('imagens') !== null) && (data.get('tipo_cliente') !== null)) {

        api.post('/consultor/documentacao/consultor', data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
          .then((response) => {
            console.log('Sucesso', response.data)
            this.setState({
              carregando: false
            })
            resolve()
          })
          .catch((err) => {
            console.log('Deu Erro', err.response)
            if (err.response.status === 406) {

              this.setState({
                erroCadastro: true,
                errorMsg: err.response.data.message,
                carregando: false
              })
            }
            reject()
          })
      } else {
        reject()
      }
    })

  }

  handleUploadDocumentacao(idCliente, tipoUsuario) {
    this.state.listaDocumentosCompletos.map(async (value, index) => {

      const data = new FormData()

      data.delete('imagens')
      data.delete('tipo_cliente')

      if ((value.state === 'documentoFotoEquipamento') && (tipoUsuario === 3)) {
        data.set('imagens', value.valor, 'foto_equipamento_' + idCliente)
        data.set('tipo_cliente', tipoUsuario)
        data.set('idConsultor', idCliente)
      } else if ((value.state === 'documentoCreci') && (tipoUsuario === 4)) {
        data.set('imagens', value.valor, 'creci_' + idCliente)
        data.set('tipo_cliente', tipoUsuario)
        data.set('idConsultor', idCliente)
      } else if ((value.state === 'documentoDiploma') && (tipoUsuario === 5)) {
        data.set('imagens', value.valor, 'vistoriador_diploma_' + idCliente)
        data.set('tipo_cliente', tipoUsuario)
        data.set('idConsultor', idCliente)
      } else if (((value.state === 'documentoRGAtras') || (value.state === 'documentoRGFrente') ||
        (value.state === 'documentoSelfie') || (value.state === 'documentoCnh') || (value.state === 'documentoResidencia'))
        && (tipoUsuario === 1)) {
        data.set('imagens', value.valor, value.state + '_' + idCliente)
        data.set('tipo_cliente', tipoUsuario)
        data.set('idConsultor', idCliente)
      }

      if ((data.get('imagens') !== null) && (data.get('tipo_cliente') !== null)) {
        await this.enviarDocumentacao(data)
      }

      if (index === this.state.listaDocumentosCompletos.length - 1) {
        history.push({
          pathname: '/obrigado',
          state: {
            msg: true,
          }
        })
      }
    })
  }

  insertDocument(state, value, type, name) {
    const dictDocumentos = {
      tipo: type,
      nome: name,
      valor: value,
      state
    }

    if (state === 'documentoRGAtras') {
      this.setState({
        documentoRGAtras: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoRGFrente') {
      this.setState({
        documentoRGFrente: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoSelfie') {
      this.setState({
        documentoSelfie: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoCnh') {
      this.setState({
        documentoCnh: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoResidencia') {
      this.setState({
        documentoResidencia: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoFotoEquipamento') {
      this.setState({
        documentoFotoEquipamento: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoCreci') {
      this.setState({
        documentoCreci: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
    if (state === 'documentoDiploma') {
      this.setState({
        documentoDiploma: value.name,
        listaDocumentosCompletos: [...this.state.listaDocumentosCompletos, dictDocumentos]
      })
    }
  }

  testarSenha = () => {
    if (this.state.senha === '') {
      this.setState({ senhaVazia: true })
      setTimeout(() => {
        this.setState({ senhaVazia: false })
      }, 2000)
    }
  }

  async login() {
    this.setState({ carregando: true })

    let err = 0

    this.state.tipo.map(value => {
      // indicador e outros
      if ((value === 2) && ((this.state.nome === '') || (this.state.email === '') || (this.state.fone === '')
        || (this.state.documento === '') || (this.state.senha === '') || (this.state.cep === ''))) {
        err++
      }
      // consultor de foto
      if ((value === 3) && ((this.state.documentoSelfie === '') || (this.state.documentoFotoEquipamento === '') ||
        (this.state.especificacaoCamera === '') || (this.state.nome === '') || (this.state.email === '') ||
        (this.state.fone === '') || (this.state.documento === '') || (this.state.senha === '') ||
        (this.state.cep === ''))) {
        err++
      }
      // corretor
      if ((value === 4) && ((this.state.documentoSelfie === '') || (this.state.documentoCreci === '') ||
        (this.state.creci === '') || (this.state.nome === '') || (this.state.email === '') ||
        (this.state.fone === '') || (this.state.documento === '') || (this.state.senha === '') ||
        (this.state.cep === ''))) {
        err++
      }
      // vistoriador
      if ((value === 5) && ((this.state.documentoSelfie === '') || (this.state.documentoDiploma === '') ||
        (this.state.nome === '') || (this.state.email === '') || (this.state.fone === '')
        || (this.state.documento === '') || (this.state.senha === '') || (this.state.cep === ''))) {
        err++
      }

      if (((value === 3) || (value === 4) || (value === 5))
        && ((this.state.documentoRGAtras === '') || (this.state.documentoRGFrente === '')) && (this.state.documentoCnh === '')) {
        err++
      }
    })

    this.testarSenha()
    let doc2 = this.state.documento.replace('.', "").replace('-', "").replace('.', "")

    const body = {
      nome: this.state.nome,
      email: this.state.email,
      fone: this.state.fone,
      documento: doc2,
      senha: this.state.senha,
      cep: this.state.cep,
      logradouro: this.state.logradouro,
      numero: this.state.numero,
      complemento: this.state.complemento,
      bairro: this.state.bairro.toUpperCase(),
      IdEstado: this.state.IdEstado,
      IdMunicipio: this.state.IdMunicipio,
      tipo: "pf",
      tipo_cliente: this.state.tipo,
      fcmtoken: this.state.fcmtoken,
      creci: this.state.creci,
      ufcreci: this.state.ufcreci,
      especificacoes: this.state.especificacaoCamera,
      cnh: this.state.cnh
    }

    console.log(body)

    if ((this.testaCPF(doc2) === true) && (this.state.senha !== '' && (err <= 0))
      && (this.state.documento !== '')) {
      api.post("/consultor", body)
        .then(response => {
          this.setState({ tipo: [...this.state.tipo, 1] })
          localStorage.setItem('token', response.data.token)
          if ((body.tipo_cliente.length) === 1 && (body.tipo_cliente[0] === 2)) {
            history.push({
              pathname: '/obrigado',
              state: {
                msg: true,
              }
            })
          } else {
            this.state.tipo.map((tipoUser) => {
              this.handleUploadDocumentacao(response.data.pessoa.id, tipoUser)
            })
          }
        })
        .catch(err => {
          this.setState({ carregando: false })
          console.log('err cadastro~> ', err.response)
          if (err) {
            this.setState({
              erroCadastro: true,
              errorMsg: err.response.data.message
            })
          }
        }).finally(
          this.setState({
            erroCadastro: false,
            erroGenerico: false,
          })
        )
    } else {
      this.setState({
        formularioError: true,
        carregando: false
      })

      setTimeout(() => {
        this.setState({
          formularioError: false
        })
      }, 2000)
    }
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <>
        <Loading open={this.state.carregando} />

        {this.state.showModal === true &&
          <ModalTermosUso showModal={this.state.showModal} close={() => this.closeModal()} />}

        <div className="content-login">
          <div className="card container-login">
            <div className="card-content card-login">
              {this.state.erroCadastro === true &&
                <CustomizedSnackbars
                  variant="error"
                  message={this.state.errorMsg} />}
              {this.state.erroTipo &&
                <CustomizedSnackbars
                  variant="error"
                  message="Informe o tipo de cadastro que será feito em nossa plataforma" />}
              {this.state.cpfInvalido === true &&
                <CustomizedSnackbars
                  variant="error"
                  message="CPF Inválido" />}
              {this.state.senhaVazia === true &&
                <CustomizedSnackbars
                  variant='error'
                  message='Senha Vazia'
                />
              }
              {this.state.erroGenerico &&
                <CustomizedSnackbars
                  variant="error"
                  message={this.state.messageError} />}
              {this.state.cepFail === true &&
                <CustomizedSnackbars
                  variant="error"
                  message="Ocorreu um erro ao tentar preencher o cep. Preencha manualmente ou tente novamente" />}
              {this.state.erroCorretor &&
                <CustomizedSnackbars
                  variant="error"
                  message="Para ser Consultor Corretor no PlanoB você precisa informar o seu CRECI e a UF do Creci" />}
              {this.state.erroEspacoBranco &&
                <CustomizedSnackbars
                  variant="error"
                  message="Preencha todos os campos antes de prosseguir" />}
              {this.state.formularioError &&
                <CustomizedSnackbars
                  variant="error"
                  message="Preencha todos os campos do Formulário antes de prosseguir" />}

              {window.outerWidth < 760 &&
                <a href={() => history.goBack()} className="column btn-login" style="float: left">Voltar</a>}

              <img className="img-login" src={logo} alt="" />

              <h4 className="title is-5">Cadastro de Novo Consultor</h4>

              {this.state.setup === 1 &&
                <div>
                  <h4 className="title is-6" style={{ marginTop: 5, textAlign: 'center', marginBottom: 0 }}>Escolha o tipo de Consultor que você deseja:</h4>

                  {window.outerWidth < 760 ?

                    (<div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                      {this.state.labelChip.map((i, index) => (
                        <Chip
                          onClick={() => this.selecionarTipoConsultor(i, index)}
                          key={`tipo_${index}`}
                          label={i.label}
                          style={(i.estilo === this.state.massa[index].estilo ? this.state.estiloOff : this.state.estiloOn),
                            { marginTop: 5, marginBottom: 2 }
                          }
                        />
                      ))}
                    </div>)
                    :
                    (this.state.labelChip.map((i, index) => (
                      <Chip
                        onClick={() => this.selecionarTipoConsultor(i, index)}
                        key={`tipo_${index}`}
                        label={i.label}
                        style={(i.estilo === this.state.massa[index].estilo ? this.state.estiloOff : this.state.estiloOn)}
                      />
                    )))
                  }

                  <br />

                  <Input
                    type="text"
                    placeholder="Nome Completo"
                    icon="fas fa-user"
                    chave="nome"
                    value={this.state.nome}
                    onChange={(e) => this.setState({ nome: e.target.value })}
                  />

                  <Input
                    type="email"
                    placeholder="E-mail"
                    icon="fas fa-at"
                    chave="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />

                  <InputMask
                    mask="(99) 99999-9999"
                    value={this.state.fone}
                    onChange={(e) => this.setState({ fone: e.target.value })}>
                    {() =>
                      <div className="field">
                        <div className="control has-icons-left has-icons-right">
                          <input
                            className="input"
                            placeholder="Celular"
                            value={this.state.fone} />
                          <span className="icon is-small is-left">
                            <i className="fas fa-mobile-alt"></i>
                          </span>
                        </div>
                      </div>}
                  </InputMask>

                  <InputMask
                    mask="999.999.999-99"
                    value={this.state.documento}
                    onChange={(e) => this.setState({ documento: e.target.value })}>
                    {() =>
                      <div className="field">
                        <div className="control has-icons-left has-icons-right">
                          <input
                            className={"input " + (this.state.showErrorCpf ? this.state.invalidCpf ? "is-danger" : "is-success" : "")} placeholder="CPF"
                            value={this.state.documento}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-address-card"></i>
                          </span>
                        </div>
                        {this.state.showErrorCpf ? this.state.invalidCpf ? <p className="help is-danger">Informe um CPF válido</p> : <p className="help is-success">CPF Válido</p> : <p></p>}
                      </div>}
                  </InputMask>

                  {this.state.isCorretor &&
                    <Input
                      type="text"
                      placeholder="Informe seu CRECI"
                      icon="fas fa-address-card"
                      chave="creci"
                      value={this.state.creci}
                      onChange={(e) => this.setState({ creci: e.target.value })}
                    />
                  }

                  {this.state.isCorretor && <div className="select-cadastro">
                    <div className="columns">
                      <div className="column is-full">
                        <div className="select">
                          <select
                            onChange={(e) => this.setState({ ufcreci: e.target.value })}
                          >
                            <option>Selecione a UF do Creci</option>
                            {this.state.listaEstados.map((estado, index) =>
                              <option key={index} value={estado.uf} selected={(estado.uf === this.state.ufcreci ? true : false)}>
                                {estado.descricao}
                              </option>)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  }

                  {(this.state.tipoConsultorFoto) &&
                    <Input
                      type="text"
                      placeholder="Especificações da Câmera"
                      icon="fas fa-camera-retro"
                      chave="especificacaoCamera"
                      value={this.state.especificacaoCamera}
                      onChange={(e) => this.setState({ especificacaoCamera: e.target.value })}
                    />
                  }

                  <If test={this.state.tipoConsultorFoto || this.state.tipoCorretor || this.state.tipoVistoriador}>
                    <Input type="text"
                      placeholder="Número da CNH"
                      icon="fas fa-file-alt"
                      chave="cnh"
                      value={this.state.cnh}
                      onChange={(e) => this.setState({ cnh: e.target.value })}
                    />
                  </If>

                  <Input
                    type="password"
                    placeholder="Senha"
                    icon="fas fa-lock"
                    chave="senha"
                    value={this.state.senha}
                    onChange={(e) => this.setState({ senha: e.target.value })}
                  />
                </div>
              }

              {this.state.setup === 2 &&
                <div>
                  <InputMask
                    mask="99999-999"
                    value={this.state.cep}
                    onChange={(e) => this.autoCep(e)}>
                    {() =>
                      <div className="field">
                        <div className="control has-icons-left has-icons-right">
                          <input
                            className="input"
                            placeholder="CEP"
                            value={this.state.cep}
                          />
                          <span className="icon is-small is-left">
                            <i className="fas fa-thumbtack"></i>
                          </span>
                        </div>
                      </div>}
                  </InputMask>

                  {this.state.loadCep === true ?
                    <div className="center-progress">
                      <CircularProgress />
                    </div>
                    :
                    <div>
                      <Input
                        type="text"
                        placeholder="Logradouro"
                        icon="fas fa-compass"
                        chave="logradouro"
                        value={this.state.logradouro}
                        onChange={(e) => this.setState({ logradouro: e.target.value })}
                      />

                      <Input
                        type="text"
                        placeholder="Número"
                        icon="fas fa-home"
                        chave="numero"
                        value={this.state.numero}
                        onChange={(e) => this.setState({ numero: e.target.value })}
                      />

                      <Input
                        type="text"
                        placeholder="Complemento"
                        icon="fas fa-map-pin"
                        chave="complemento"
                        value={this.state.complemento}
                        onChange={(e) => this.setState({ complemento: e.target.value })}
                      />

                      <div className="select-cadastro">
                        <div className="columns">
                          <label className="column label-login" htmlFor="estado">Estado: </label>
                          <div className="column is-full">
                            <div className="select">
                              <select
                                onChange={(e) => this.pegarCidade(e.target.value)}
                              >
                                <option>Selecione</option>
                                {this.state.listaEstados.map((estado, index) =>
                                  <option key={index} value={estado.id} selected={(estado.uf === this.state.estado ? true : false)}>
                                    {estado.descricao}
                                  </option>)}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.disabled &&
                        <div className="select-cadastro">
                          <div className="columns">
                            <label className="column label-login" htmlFor="estado">Município: </label>
                            <div className="column is-full">
                              <div className="select">
                                <select
                                  onChange={(e) => this.pegarBairro(e.target.value)}>
                                  <option>Selecione o Município</option>
                                  {this.state.listaMunicipio.map((municipio, index) =>
                                    <option
                                      value={municipio.id} selected={(municipio.descricao === this.state.municipio ? true : false)}
                                      key={index}
                                    >
                                      {municipio.descricao}
                                    </option>)}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>}
                      {this.state.disabledBairro &&
                        <div className="select-cadastro">
                          <div className="columns">
                            <label className="column label-login" htmlFor="estado">Bairro: </label>
                            <div className="column is-full">
                              <div className="select">
                                <select
                                  onChange={(e) => this.setState({ bairro: e.target.value })}
                                >
                                  <option selected >Selecione o Bairro</option>
                                  {this.state.listaBairros.map((bairro, index) =>
                                    <option
                                      value={bairro.id}
                                      selected={(bairro.nome.toLowerCase() === this.state.bairro.toLowerCase() ? true : false)}
                                      key={index}>{bairro.nome}
                                      >
                                    </option>)}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </div>}
                </div>
              }

              {this.state.setup === 3 &&
                <form>
                  <h4 className="title is-4" style={{ marginBottom: 10 }}>Documentos Gerais</h4>
                  <div style={this.state.containerBorder}>
                    <div style={{ margin: 10 }}>
                      <label><strong>Selfie:</strong></label><br />
                      <div className='file has-name'>
                        <label className="file-label">
                          <input
                            type="file"
                            className="file-input"
                            onChange={(e) => this.insertDocument('documentoSelfie', (e.target.files[0]) ? e.target.files[0] : '', 'Selfie', 1)}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                              Escolher o arquivo
                            </span>
                          </span>
                          <span className="file-name">
                            {this.state.documentoSelfie ? this.state.documentoSelfie : 'Escolha o arquivo'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div style={this.state.containerBorder}>
                    <div style={{ margin: 10 }}>
                      <label>
                        <div
                          className="select"
                          style={{ width: '100%', marginBottom: 10 }}
                        >
                          <select
                            style={{ width: '100%' }}
                            value={this.state.documentoFotoEscolhido}
                            onChange={(e) => this.setState({ documentoFotoEscolhido: e.target.value })}
                          >
                            <option style={{ fontWeight: 'bold' }} value='rg'>RG (Frente e Verso):</option>
                            <option style={{ fontWeight: 'bold' }} value='cnh'>CNH:</option>
                          </select>
                        </div>
                      </label><br />
                      {(this.state.documentoFotoEscolhido === 'cnh') ? (

                        <div className='file has-name'>
                          <label className="file-label">
                            <input
                              type="file"
                              className="file-input"
                              onChange={(e) => this.insertDocument('documentoCnh', (e.target.files[0]) ? e.target.files[0] : '', 'CNH', 1)}
                            />
                            <span className="file-cta">
                              <span className="file-icon">
                                <i className="fas fa-upload"></i>
                              </span>
                              <span className="file-label">
                                Escolher o arquivo
                            </span>
                            </span>
                            <span className="file-name">
                              {this.state.documentoCnh ? this.state.documentoCnh : 'Escolha o arquivo'}
                            </span>
                          </label>
                        </div>
                      ) : (
                          <>
                            <div className='file has-name'>
                              <label className="file-label">
                                <input
                                  type="file"
                                  className="file-input"
                                  onChange={(e) => this.insertDocument('documentoRGFrente', (e.target.files[0]) ? e.target.files[0] : '', 'RG Frente', 1)}
                                />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-label">
                                    Escolher o arquivo
                                  </span>
                                </span>
                                <span className="file-name">
                                  {this.state.documentoRGFrente ? this.state.documentoRGFrente : 'Escolha o arquivo Frente RG'}
                                </span>
                              </label>
                            </div>

                            <div className='file has-name'>
                              <label className="file-label">
                                <input
                                  type="file"
                                  className="file-input"
                                  onChange={(e) => this.insertDocument('documentoRGAtras', (e.target.files[0]) ? e.target.files[0] : '', 'RG Verso', 1)}
                                />
                                <span className="file-cta">
                                  <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                  </span>
                                  <span className="file-label">
                                    Escolher o arquivo
                                </span>
                                </span>
                                <span className="file-name">
                                  {this.state.documentoRGAtras ? this.state.documentoRGAtras : 'Escolha o arquivo Costas RG'}
                                </span>
                              </label>
                            </div>
                          </>
                        )}
                    </div>
                  </div>

                  <div style={this.state.containerBorder}>
                    <div style={{ margin: 10 }}>
                      <label><strong>Comprovante de Residência:</strong></label><br />
                      <div className='file has-name'>
                        <label className="file-label">
                          <input
                            type="file"
                            className="file-input"
                            onChange={(e) => this.insertDocument('documentoResidencia', (e.target.files[0]) ? e.target.files[0] : '', 'Comprovante de Residência', 1)}
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="fas fa-upload"></i>
                            </span>
                            <span className="file-label">
                              Escolher o arquivo
                            </span>
                          </span>
                          <span className="file-name">
                            {this.state.documentoResidencia ? this.state.documentoResidencia : 'Escolha o arquivo'}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {this.state.tipoConsultorFoto &&
                    <>
                      <h4 className="title is-4" style={{ marginBottom: 10 }} > Documento Consultor de Imagem</h4>
                      <div style={this.state.containerBorder}>
                        <div style={{ margin: 10 }}>
                          <label><strong>Foto do Equipamento:</strong></label> <br />
                          <div className='file has-name'>
                            <label className="file-label">
                              <input
                                type="file"
                                className="file-input"
                                onChange={(e) => this.insertDocument('documentoFotoEquipamento', (e.target.files[0]) ? e.target.files[0] : '', 'Foto Equipamento', 3)}
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                  Escolher o arquivo
                              </span>
                              </span>
                              <span className="file-name">
                                {this.state.documentoFotoEquipamento ? this.state.documentoFotoEquipamento : 'Escolha o arquivo'}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  {this.state.tipoCorretor &&
                    <>
                      <h4 className="title is-4" style={{ marginBottom: 10 }}>Documento Consultor Corretor</h4>
                      <div style={this.state.containerBorder}>
                        <div style={{ margin: 10 }}>
                          <label><strong>Carteira do CRECI:</strong></label><br />
                          <div className='file has-name'>
                            <label className="file-label">
                              <input
                                type="file"
                                className="file-input"
                                onChange={(e) => this.insertDocument('documentoCreci', (e.target.files[0]) ? e.target.files[0] : '', 'Carteira do Creci', 4)}
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                  Escolher o arquivo
                              </span>
                              </span>
                              <span className="file-name">
                                {this.state.documentoCreci ? this.state.documentoCreci : 'Escolha o arquivo'}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  {this.state.tipoVistoriador &&
                    <>
                      <h4 className="title is-4" style={{ marginBottom: 10 }}>Documento Consultor Vistoriador</h4>
                      <div style={this.state.containerBorder}>
                        <div style={{ margin: 10 }}>
                          <label><strong>Diploma ou Declaração de Curso (Engenharia Civil/ Arquitetura / Tec. Edificação):</strong></label><br />
                          <div className='file has-name'>
                            <label className="file-label">
                              <input
                                type="file"
                                className="file-input"
                                onChange={(e) => this.insertDocument('documentoDiploma', (e.target.files[0]) ? e.target.files[0] : '', 'Diploma/Declaração', 5)}
                              />
                              <span className="file-cta">
                                <span className="file-icon">
                                  <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                  Escolher o arquivo
                              </span>
                              </span>
                              <span className="file-name">
                                {this.state.documentoDiploma ? this.state.documentoDiploma : 'Escolha o arquivo'}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </form>
              }

              <If test={this.state.setup === 1}>
                <button className="column btn-login"
                  onClick={() => this.setup(2)}>Avançar</button>
              </If>
              <If test={this.state.setup === 2}>
                <div className="columns">
                  <button className="column btn-login"
                    onClick={() => this.setup(1)}>Voltar</button>
                  <If test={this.state.tipo.includes(2)}>
                    <If test={this.state.tipo.find(() => this.state.tipo < 3) === 2}>
                      <button className="column btn-login"
                        onClick={() => this.login()}>CADASTRAR</button>
                    </If>
                    <If test={this.state.tipo.find(() => this.state.tipo > 2) === undefined && this.state.tipo.find(() => this.state.tipo < 3) !== 2}>
                      <button className="column btn-login"
                        onClick={() => this.setup(3)}>AVANÇAR</button>
                    </If>
                  </If>
                  <If test={!this.state.tipo.includes(2)}>
                    <button className="column btn-login"
                      onClick={() => this.setup(3)}>AVANÇAR</button>
                  </If>
                </div>
              </If>
              <If test={this.state.setup === 3}>
                <div className="columns" style={{ marginTop: 20 }}>
                  <button className="column btn-login"
                    onClick={() => this.setup(2)}>VOLTAR</button>
                  <button className="column btn-login"
                    onClick={() => this.login()}>ENVIAR</button>
                </div>
              </If>

              <Link to="/consultor/login" className="nova-conta">Já possui conta? Faça Login</Link>
              <Link to="/consultor/recuperar-senha" style={{ textAlign: 'center' }} >Esqueceu sua senha? Recupere sua senha.</Link>
              <p style={{ marginTop: 20 }} >Ao se cadastrar, você aceita os
              <a onClick={() => this.setState({ showModal: true })} style={{ color: '#3273DC' }} > termos de uso</a>.</p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  app: state.app,
  imovel: state.imovel
})

export default withRouter(connect(mapStateToProps)(Cadastro))
