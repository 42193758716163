import React from 'react';
import Gallery from 'react-grid-gallery';
import api from "../../services/api"


class Galeria extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
            currentImage: 0
        };

        this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }

    componentWillMount() {
        this.getFotos()
    }


    onCurrentImageChange(index) {
        this.setState({ currentImage: index });
    }

    deleteImage() {
        if (window.confirm(`Tem certeza que deseja apagar essa imagem?`)) {
            var images = this.state.images.slice()
            var atual = images[this.state.currentImage]
            console.log(atual.id)
            if (atual.status === 'sim') {
                alert('Você Não Pode apagar uma foto que foi aprovada')
            } else {

                images.splice(this.state.currentImage, 1)
                this.setState({
                    images: images
                })
                api.delete(`/consultor/deleteFoto/${atual.id}`)
                    .then((response) => {
                        console.log(response.data)
                        console.log('ok', atual)
                    })
                    .catch((response) => console.log(response.data))
            }

        }
    }

    getFotos(){
        console.log('fotosMount~> ', this.props.fotos)
        let images = []
        this.props.fotos.map((value, index) => {
            images.push({
                id: value.id,
                src: value.link,
                thumbnail: value.link,
                liberado: value.liberado,
                thumbnailWidth: 320,
                thumbnailHeight: 212
            })
        })
        console.log('resultado images~> ', images)
        this.setState({
            images
        })

        console.log('fotos1~> ', this.props.fotos)
        console.log('imagens~> ', this.state.images)
    }

    render() {
        console.log('fotos2~> ', this.props.fotos)
        return (
            <Gallery
                images={this.state.images}
                enableLightbox={true}
                enableImageSelection={false}
                currentImageWillChange={this.onCurrentImageChange}
                customControls={[
                    <button key="deleteImage" className="button is-danger is-small" onClick={this.deleteImage}>Apagar Imagem</button>,
                ]}
            />
        );
    }
}

export default Galeria