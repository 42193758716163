import React, { useState } from "react"

export default props => {
    const [enviando, setEnviando] = useState(false)

    const enviarLink = () => {
        setEnviando(true)
        props.enviarLink()
        localStorage.setItem('enviarConfirmacaoEmail', '1')
    }

    return (
        <div
            className="modal animated fadeIn faster"
            style={{ display: props.open ? "block" : "none" }}
        >
            <div className="modal-background" />
            <div className="modal-content">
                <div className="modal-body">
                    <article className="message is-warning">
                        <div className="message-header">
                            <p>Aviso</p>
                        </div>
                        <div className="message-body">
                            Seu email <em>{localStorage.getItem("email")}</em> não foi confirmado.
                            <div style={{ marginTop: 10 }}></div>
                            {enviando ? (
                                <p>Evniando link...</p>
                            ) : (
                                    <button className="button is-small" onClick={() => enviarLink()}>
                                        Enviar Confirmação
                                </button>
                                )}
                        </div>
                    </article>
                </div>
            </div>
            <button
                onClick={() => props.close()}
                className="modal-close is-large"
                aria-label="close"
            />
        </div>
    )
}
