import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import app from "./app"
import imovel from "./imovel"
import auth from "./auth"
import atendimento from "./atendimento"
import midias from "./midias"
import consultor from "./consultor"

export default combineReducers({
    app,
    form: formReducer,
    imovel,
    auth,
    atendimento,
    midias,
    consultor
})
