import React from "react"
import If from "../common/IF"
import api from '../../services/api'

const MenuItem = props => {

    const planobLogout = async c => {
        api.post('/auth/logout').then((result) => {
            console.log('apagou ', result)
            localStorage.clear()
            c.goToPage('/consultor/login')
        }).catch((err) => console.log('error apagou ', err.response))
    }

    return (
        <>
            <li className={props.menu.link === `${props.app.page}` ? "active" : ""}>
                <If test={props.menu.link === "/sair"}>
                    <span onClick={() => planobLogout(props)}>
                        {props.menu.label}
                    </span>
                </If>
                <If test={props.menu.link !== "/sair"}>
                    <span onClick={() => props.goToPage(props.menu.link)}>
                        {props.menu.label}
                    </span>
                </If>
            </li>
        </>
    )
}

export default MenuItem
