import React from "react"
import loading from "../../images/loading.svg"

export default props => {
    if (props.open === true) {
        return (
            <div className="loading">
                <img src={loading} alt="loading" />
                Aguarde
            </div>
        )
    } else {
        return <span />
    }
}
