import React from 'react'

const Input = (props) => {
    return (
        <div className="field">
            <div className="control has-icons-left has-icons-right">
                <input type={props.type} className="input"
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e)
                    }} />
                <span className="icon is-small is-left">
                    <i className={props.icon}></i>
                </span>
            </div>
        </div>
    )
}


export default Input