import React, { Component } from 'react'
import history from "../../routes/history"
import api from "../../services/api"


class Ambientes extends Component {

    state = {
        open: false,
        hamburguer: true
    }

    // renderRow(row){
    //     return ()
    // }

    delete = function(e,id){

        console.log(id);

        api.delete("/vistorias/deletarAmbiente/"+id)
        .then(response => {
            console.log(response.data)
            history.push("/");
            history.push("/laudo/"+this.props.ambiente.idVistoria)
        })
        .catch(error => console.log(error.response))

    }

    editar = function(e,id){

        history.push("/infoambiente/"+id);

    }

    render() {
        return (
            <div>
                <article class="message is-info">
                    <div class="message-header">
                        {this.props.ambiente.titulo}
                        <button onClick={(e) => this.delete(e,this.props.ambiente.id)} class="delete"></button>
                    </div>
                    <div class="message-body">
                        {this.props.ambiente.descricoes.map((row,index) => (<p>
            <span  style={{marginRight:5}} class="icon">
                    </span><span style={{marginRight:10}} class="icon" onClick={(e)=>this.editar(e,row.id)}><i class="fas fa-pen"></i></span>{row.descricao}</p>))}
                    </div>
                </article>
            </div>
        )
    }
}

export default Ambientes