export const Types = {
    LOADING: "app/loading"
}

const TOKEN_KEY = "@planob_auth"

const INITIAL_STATE = {
    loading: false,
    user: "",
    token: ""
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.LOADING:
            return { ...state, loading: action.loading }

        default:
            return state
    }
}

export const getToken = () => JSON.parse(localStorage.getItem(TOKEN_KEY))

export const Creators = {
    loadding: loading => {
        return {
            type: Types.LOADING,
            loading
        }
    },
    login: credencials => dispatch => {}
}
