import React, { Component } from 'react'
import If from "../common/IF"
import { format } from 'date-fns';

class NewCardAtendimento extends Component {

    render() {
        const { atendimento } = this.props
        const telefones = atendimento.idImovel.proprietarios[0].Telefones;


        return (
            <>
                <div className="column is-full">
                    <div className="card">
                        <div className="card-content">
                            <div className="columns">
                                <div className="column is-3 center-info-card">
                                    <img style={{ width: 250, alignSelf: 'center' }} src={atendimento.idImovel.capa !== null ? atendimento.idImovel.capa : "https://planob.classeloc.com.br//images/semfoto.png"} alt="" />
                                </div>
                                <div className="column row-info-card">
                                    <h6 style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>Endereço</h6>
                                    <p className="is-size-7" style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>{atendimento.idImovel.endereco}</p>
                                </div>
                                <div className="column row-info-card">
                                    <h6 style={window.innerWidth > 640 ? { textAlign: 'left' } : {}}>Informações do Proprietário</h6>
                                    <p style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}>
                                        <strong>Nome:</strong> {atendimento.Pretendente.length > 0 ? atendimento.idImovel.proprietarios[0].Nome : 'Não Informado'}
                                    </p>
                                    <p style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}>
                                        <strong>E-mail:</strong> {atendimento.Pretendente.length > 0 ? atendimento.idImovel.proprietarios[0].Email : 'Não Informado'}
                                    </p>
                                    {telefones.length > 0 ? telefones.map((telefone, index) => (
                                        telefone !== "" &&
                                        <p key={index} style={window.innerWidth > 640 ? { textAlign: 'left', margin: 2, fontSize: 12 } : {}}><strong>Telefone:</strong> {telefone}</p>
                                    )) : <p></p>
                                    }
                                    <div className="column content-action">
                                        <button
                                            onClick={() => this.props.detalhe(this.props.index)}
                                            className="button is-info is-small"
                                            style={{ marginTop: 10, fontWeight: 'bold', justifyContent: 'center', textAlign: 'center', maxWidth: 200 }}
                                        >
                                            Detalhes
                                        </button>
                                    </div>
                                </div>
                                <div className="column is-2 row-info-card">
                                    <h6 style={{ textAlign: 'center' }}>Ações</h6>
                                    <div className="column content-action">
                                        <p className="is-size-7" style={{ textAlign: 'center', fontWeight: 'bold' }}>Horário: {format(atendimento.datahora, 'DD/MM/YYYY - HH:mm')}</p>

                                        <If test={atendimento.status === "Agendado" || atendimento.status === "Reagendado"}>
                                            <If test={atendimento.resposta !== 'ACEITO' && atendimento.resposta !== 'RECUSADO'}>
                                                <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'green' }}>NOVO PROJETO</p>
                                                <button
                                                    onClick={() => this.props.confirmaAtendimento('aceitar', atendimento.id)}
                                                    className="button is-success is-primary is-small"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    Aceitar
                                        </button>
                                                <button
                                                    onClick={() => this.props.confirmaAtendimento('recusar', atendimento.id)}
                                                    className="button is-danger is-primary is-small"
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    Recusar
                                        </button>
                                            </If>
                                            <If test={atendimento.resposta === 'ACEITO'}>
                                                <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'orange' }}>PROJETO ACEITO </p>
                                                <button
                                                    onClick={() => this.props.iniciaAtendimento(atendimento, atendimento.id)}
                                                    className="button is-info btnPrimary is-small" disabled={localStorage.getItem('visitaIniciada') === 'sim' ? true : false}
                                                    style={{ fontWeight: 'bold' }}
                                                >
                                                    Iniciar Visita
                                            </button>
                                            </If>
                                            <If test={atendimento.resposta === 'RECUSADO'}>
                                                <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>VOCÊ RECUSOU O PROJETO</p>
                                            </If>
                                        </If>
                                        <If test={atendimento.status === "Em visita"}>
                                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'orange' }}>VOCÊ ESTÁ EM VISITA</p>
                                            <button
                                                onClick={() => this.props.encerrarAtendimento(atendimento.id, atendimento.idImovel.id)}
                                                className={`button is-small is-danger`} disabled={localStorage.getItem('visitaEncerrada') === 'sim' ? true : false}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Encerrar Visita
                                            </button>
                                            <button
                                                onClick={() => this.props.observacoes(atendimento.id, atendimento, this.props.index)}
                                                className={`button is-small is-info is-small`}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Observações
                                        </button>
                                        </If>
                                        <If test={atendimento.status === "Confirmado"}>
                                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'green' }}>VISITA FINALIZADA</p>
                                            <button
                                                onClick={() => this.props.observacoes(atendimento.id, atendimento, this.props.index)}
                                                className={`button is-info is-small`}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Observações
                                        </button>
                                        </If>
                                        <If test={atendimento.status === "Cancelado"}>
                                            <p style={{ textAlign: 'center', fontWeight: 'bold', color: 'red' }}>CANCELADO</p>
                                            <button
                                                onClick={() => this.props.observacoes(atendimento.id, atendimento, this.props.index)}
                                                className={`button is-info is-small`}
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Observações
                                        </button>
                                        </If>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default NewCardAtendimento

//const 
