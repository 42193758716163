import React from 'react'
import api from "../../services/api"
import { Modal } from 'reactstrap'

class ModalUploadVideo extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    state = {
        openProgressBar: false,
        progressBar: '',
        openModalVideo: false,
        modal: false,
        files: null,
        uploading: false,
        progresso: false,
        porcentagem: 0,
        statusProgress: 'active',
        verificarMovel: false
    }

    componentDidMount() {
        const width = window.outerWidth
        if (width < 760) {
            this.setState({ verificarMovel: true })
        }
    }

    handleUploadFileVideo(e) {
        const arquivos = e.target.files
        this.setState({
            files: arquivos
        })
    }

    handleUploadVideo = (idImovel, idProjeto) => {
        if (this.state.verificarMovel === false) {
            const files = this.state.files
            const data = new FormData()
            data.append('multipleFiles[]', files[0], files[0].name)
            data.append('id', idImovel)
            data.append('idProjeto', idProjeto)
            this.enviarVideo(data)
        } else {
            alert('Celulares ou Tablets são proibidos de enviar mídia')
        }
    }

    modalVideo = () => {
        this.setState({
            openModalVideo: !this.state.openModalVideo
        })
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    enviarVideo(data) {
        this.setState({ openProgressBar: true })
        api.post('/consultor/enviarvideos/', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                this.setState({
                    progressBar: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                })
            }
        })
            .then((response) => {
                console.log('Deu Certo', response.data)
                this.props.listarImoveis()
                this.props.snackSuccess(true)
                api.get('/consultor/avisarDeEnvio/' + this.props.idImovel)
                this.setState({
                    openProgressBar: false,
                    openModalVideo: false,
                })
                this.toggle()
            })
            .catch((error) => {
                console.log('Deu Erro', error)
                this.props.snackError(true)

                this.setState({
                    openProgressBar: false,
                    openModalVideo: false,
                })
                this.toggle()
            })
        setTimeout(() => {
            this.props.snackSuccess(false)
            this.props.snackError(false)
        }, 4000);
    }


    render() {
        return (
            <>
                <div className="button is-info is-small" onClick={this.toggle}>
                    <p>Enviar Vídeo</p>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal" style={{ backgroundColor: 'rgba(52, 52, 52, 0.8)' }}>
                    <div className="modal animated fadeIn faster">
                        <div className="modal-background" />
                        <div className="modal-card">
                            <header toggle={this.toggle} className="modal-card-head">
                                <p className="modal-card-title">Upload de Vídeo - {this.props.idImovel}</p>
                                <button
                                    className="button fechar-modal"
                                    onClick={this.toggle}
                                ><strong>fechar</strong></button>
                            </header>
                            <section className="modal-card-body">
                                {this.state.openProgressBar && <progress style={{ width: '200px' }} class="progress is-link" value={this.state.progressBar} max="100">{this.state.progressBar}</progress>}
                                <form encType="multipart/form-data">

                                    <label>Selecione o Vídeo: </label><br />
                                    <div className='file has-name'>
                                        <label className="file-label">
                                            <input type="file" className="file-input" onChange={(e) => this.handleUploadFileVideo(e)} required />
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <i className="fas fa-upload"></i>
                                                </span>
                                                <span className="file-label">
                                                    Escolher o arquivo
                                                </span>
                                            </span>
                                            <span className="file-name">
                                                {this.state.files ? this.state.files[0].name : 'Escolha o arquivo'}
                                            </span>
                                        </label>
                                    </div>

                                </form>
                            </section>
                            <footer className="modal-card-foot">
                                <button className="button is-success" onClick={() => this.handleUploadVideo(this.props.idImovel, this.props.idProjeto)}>Enviar</button>
                                <button className="button" onClick={this.modalVideo}>Cancelar</button>
                            </footer>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }

}

export default ModalUploadVideo