import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import VistoriaCard from '../components/cards/VistoriaCard'
import api from "../services/api"
import If from '../components/common/IF'
import Loading from '../components/common/Loading'

class Vistorias extends Component {

    state = {
        hamburguer: true,
        vistorias: [],
        carregando: true
    }

    componentDidMount = () => {
        if (window.innerWidth <= 640) {
            this.setState({
                hamburguer: false,
            })
        }
        this.listarVistorias()
    }

    listarVistorias = () => {
        api.get('/vistorias/vistoriasAgendadas')
            .then((response) => {
                this.setState({
                    vistorias: response.data.listagem,
                    carregando: false
                })
            })
            .catch((err) => {
                console.log('err ', err)
                this.setState({
                    carregando: false
                })
            })
    }


    aceitarVistoria = idVistoria => {
        this.setState({ carregando: true })

        api.get('/vistorias/aceitarVistoria', {
            params: {
                vistoria: idVistoria
            }
        })
            .then((response) => {
                this.listarVistorias()
                this.setState({ carregando: false })
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
                this.setState({ carregando: false })
            })
    }

    recusarVistoria = idVistoria => {
        this.setState({ carregando: true })

        api.get('/vistorias/recusarVistoria', {
            params: {
                vistoria: idVistoria
            }
        })
            .then((response) => {
                this.listarVistorias()
                this.setState({ carregando: false })
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
                this.setState({ carregando: false })
            })
    }

    iniciarVistoria = idVistoria => {
        this.setState({ carregando: true })
        api.get('/vistorias/iniciarVistoria', {
            params: {
                vistoria: idVistoria
            }
        })
            .then((response) => {
                this.listarVistorias()
                this.setState({ carregando: false })
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
                this.setState({ carregando: false })
            })
    }

    confirmarVistoria = idVistoria => {
        this.setState({ carregando: true })
        api.get('/vistorias/confirmarVistoria', {
            params: {
                vistoria: idVistoria
            }
        })
            .then((response) => {
                this.listarVistorias()
                this.setState({ carregando: false })
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
                this.setState({ carregando: false })
            })
    }

    cancelarVistoria = idVistoria => {
        this.setState({ carregando: true })

        api.get('/vistorias/cancelarVistoria', {
            params: {
                vistoria: idVistoria
            }
        }).then((response) => {
            this.listarVistorias()
            this.setState({ carregando: false })
            console.log(response.data)
        })
            .catch(error => {
                this.setState({ carregando: false })
                console.log(error)
            })
    }

    changeHamburger = () => {
        console.log(this.state.hamburguer)
        this.setState({
            hamburguer: !this.state.hamburguer
        })
    }

    render() {
        return (
            <div className="main">
                {this.state.carregando === true ? <Loading open={this.state.carregando} /> : null}
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
                    {this.state.envio}
                    <If test={this.state.envio === true}><p>teste</p></If>
                    <div className="columns is-multiline">
                        {this.state.vistorias.length > 0 ?
                            this.state.vistorias.map((vistoria, index) => (
                                <div key={index} className="column is-one-quarter">
                                    <VistoriaCard
                                        listar={this.listarVistorias}
                                        vistoria={vistoria}
                                        aceitarVistoria={this.aceitarVistoria}
                                        recusarVistoria={this.recusarVistoria}
                                        iniciarVistoria={this.iniciarVistoria}
                                        confirmarVistoria={this.confirmarVistoria}
                                        cancelarVistoria={this.cancelarVistoria}
                                    />
                                </div>
                            )) : <div style={{ height: '100%', width: '100%' }}>
                                <h1 className="center-progress" style={{textAlign: 'center'}}>Sem demandas registradas</h1>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Vistorias