import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import api from "../services/api"
import history from "../routes/history"


class CadastroAmbiente extends Component {

    constructor(props) {

        super(props);

        this.state = {
            hamburguer: true,
            "idVistoria":this.props.match.params.id,
            "ambiente":"",
            "descricoes":[""]
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event,index) {

        console.log(index);

        if(event.target.name === "ambiente"){
            
            this.setState({"ambiente": event.target.value});
            
        }else{

            let copiaLinha = Object.assign([],this.state.descricoes);
            copiaLinha[index]=event.target.value;
            this.setState({"descricoes":copiaLinha});

        }

    }

    handleSubmit(event) {
        event.preventDefault();

        console.log(this.state);

        let descricoes = [];

        this.state.descricoes.map((item)=>descricoes.push({'descricao':item}));

        api.post("/vistorias/cadastrarAmbiente", {
            "idVistoria": this.state.idVistoria,
            "ambiente": this.state.ambiente,
            "descricoes": descricoes
        })
        .then(response => {
            console.log(response.data)
         
            history.push("/laudo/"+this.props.match.params.id)
           
        })
        .catch(error => console.log(error.response))

    }

  

    componentWillMount = () => {
        if(window.innerWidth <= 640){
            this.setState({
                hamburguer: false,
            })
        }
       
    }

    addLinha = (e) => {

        e.preventDefault();

        let copiaLinha = Object.assign([],this.state.descricoes);
        copiaLinha.push("");
        this.setState({"descricoes":copiaLinha});
    }

    render() {
        return (
            <div className="main">
                <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger}/>
                <Navbar title="Vistorias" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger}/>
                <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>



                <div className="card">
                    <div className="card-content">
                        <h4 className="card-title">Cadastro de Ambiente</h4>

                        <form id="novoAmbiente">

                            <div className="field">

                                <label className="label">Titulo do Ambiente</label>

                                <div className="control">
                                    <input className="input" name="ambiente" onChange={this.handleChange} type="text"></input>
                                </div>

                            </div>


                            <h4 className="card-title">Informações</h4>

                            {

                                this.state.descricoes.map((item,index)=>(
                                <div className="field">
                                <label className="label">Descricao</label>
                                <div class="field is-grouped">
                                    <p class="control is-expanded">
                                        <input class="input" name="titulos[]"  key={{index}} onChange={(e) => this.handleChange(e,index)} placeholder="Descrição do ambiente"  type="text" value={item} ></input>
                                    </p>
                                    <p class="control">
                                        <button  class="button is-info" onClick={this.addLinha}>
                                        +
                                        </button>
                                    </p>
                                </div>
                                </div>
                                ))
                            }




                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button is-link" onClick={(e) => this.handleSubmit(e)}>Cadastrar</button>
                                </div>
                            </div>

                        </form>
                                             
                    </div>
                                      
                </div>

                </div>
               
            </div>
        )
    }
}

export default CadastroAmbiente