import React, { Component } from 'react'
import Navbar from "../components/menu/Navbar"
import Sidebar from "../components/menu/Sidebar"
import Loading from "../components/common/Loading"
import api from "../services/api"
import CustomSnackBar from '../components/material/SnackBar'
import If from "../components/common/IF"

class DadosBancarios extends Component {

  constructor(props) {
    super(props)
    this.changeHamburger = this.changeHamburger.bind(this)
    this.selecionarAgencia = this.selecionarAgencia.bind(this)
    this.handleForm = this.handleForm.bind(this)
    this.changeHamburger = this.changeHamburger.bind(this)
    this.novaContaCliente = this.novaContaCliente.bind(this)
    this.handleEditarConta = this.handleEditarConta.bind(this)
    this.handleCancelarEdicao = this.handleCancelarEdicao.bind(this)
    this.handleNovaConta = this.handleNovaConta.bind(this)
    this.editarConta = this.editarConta.bind(this)
    this.listarContas = this.listarContas.bind(this)
  }

  state = {
    foi: false,
    edit: null,
    open: false,
    hamburguer: true,
    sucesso: false,
    erro: false,
    mensagem: '',
    tipoconta: '3',
    operacao: '',
    bancoid: '',
    agenciaid: '',
    numeroConta: '',
    idContaBanco: '',
    bancos: [],
    listaAgencia: [],
    listaTipoConta: [],
    listaContas: [],
    idClient: localStorage.getItem('id'),
    cliente: {}
  }

  selecionarAgencia(bancoid) {
    api.get(`/clientes/agencias/${bancoid}`)
      .then((response) => {
        this.setState({
          listaAgencia: response.data.agencias,
          bancoid
        })
      })
  }

  novaContaCliente() {

    const body = {
      tipoconta: this.state.tipoconta,
      operacao: this.state.operacao,
      bancoid: this.state.bancoid,
      agenciaid: this.state.agenciaid,
      numeroConta: this.state.numeroConta
    }

    console.log('body~> ', body)

    api.post('/clientes/novaConta', body)
      .then(response => {
        console.log('cadastrada', response)
        this.listarContas()
        this.handleCancelarEdicao()
        this.setState({
          sucesso: true,
          mensagem: 'Conta Bancária Cadastrada com Sucesso'
        })
      }).catch(error => {
        console.log('erro~>', error.response)
        this.setState({
          erro: true,
          mensagem: 'Erro ao Cadastrar Conta Bancária'
        })
      })
    setTimeout(() => {
      this.setState({
        erro: false,
        sucesso: false
      })
    }, 4000);
  }

  listarContas() {
    api.get(`/clientes/listaContasCliente`
    ).then((response) => {
      console.log(response.data)
      this.setState({
        cliente: response.data,
        contatos: response.data.contatos,
        open: false,
        tipoconta: response.data.contas ? response.data.contas[0].tipo.id : "",
        operacao: response.data.contas ? response.data.contas[0].operacao : "",
        bancoid: response.data.contas ? response.data.contas[0].banco.id : "",
        agenciaid: response.data.contas ? response.data.contas[0].agencia.id : "",
        numeroConta: response.data.contas ? response.data.contas[0].numero : "",
        listaContas: response.data.contas ? response.data.contas : []
      })
    }).catch((error) => {
      console.log(error)
      this.setState({
        open: false
      })
    })
  }

  editarConta = () => {
    api.put('/clientes/editarConta/' + this.state.idContaBanco, {
      tipoconta: this.state.tipoconta,
      operacao: this.state.operacao,
      bancoid: this.state.bancoid,
      agenciaid: this.state.agenciaid,
      numeroConta: this.state.numeroConta
    }).then(response => {
      console.log(response.data)
      this.listarContas()
      this.handleCancelarEdicao()
      this.setState({
        sucesso: true,
        mensagem: 'Conta Bancária Editada Com Sucesso'
      })
    }).catch(error => {
      console.log(error)
      this.setState({
        erro: true,
        mensagem: 'Erro ao Editar Conta Bancária. Tente Novamente'
      })
    })
    setTimeout(() => {
      this.setState({
        erro: false,
        sucesso: false
      })
    }, 4000);
  }

  componentDidMount() {
    this.listarContas()
    api.get('/clientes/bancos')
      .then(response => this.setState({
        bancos: response.data.bancos
      }))
    api.get('/clientes/tipoConta')
      .then(response => this.setState({
        listaTipoConta: response.data.tiposConta
      }))
    setTimeout(() => {
      api.get('/clientes/agencias/' + this.state.bancoid)
        .then(response => this.setState({
          listaAgencia: response.data.agencias
        }))
    }, 1000);
  }

  componentWillMount() {
    if (window.innerWidth <= 640) {
      this.setState({
        hamburguer: false,
      })
    }
    this.setState({
      open: true
    })
  }

  handleForm = (value, e) => {
    this.setState({
      [value]: e.target.value
    })
    console.log(value, e.target.value)
  }


  changeHamburger = () => {
    console.log(this.state.hamburguer)
    this.setState({
      hamburguer: !this.state.hamburguer
    })
  }

  handleNovaConta = () => {
    this.setState({
      foi: true
    })
  }



  handleEditarConta = (index) => {
    let conta = this.state.listaContas[index]
    this.setState({
      idContaBanco: conta.id,
      tipoconta: conta.tipo.id,
      operacao: conta.operacao,
      bancoid: conta.banco.id,
      agenciaid: conta.agencia.id,
      numeroConta: conta.numero,
      edit: index
    })
    setTimeout(() => {
      api.get('/clientes/agencias/' + this.state.bancoid)
        .then(response => this.setState({
          listaAgencia: response.data.agencias
        }))
      console.log(this.state)
    }, 500);
  }

  handleCancelarEdicao = () => {
    this.setState({
      edit: null,
      foi: false
    })
  }

  render() {
    var { bancos, listaTipoConta, listaContas } = this.state
    console.log(this.state.listaContas)
    return (
      <div className="main">
        <Loading open={this.state.open} />
        <Sidebar hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
        <Navbar title="Dados Bancários" hamburguer={this.state.hamburguer} changeHamburger={this.changeHamburger} />
        <div className={"animated content " + (this.state.hamburguer ? "paddingLeftSide bounceInLeft fast" : "bounceInRight fast")}>
          {this.state.sucesso &&
            <CustomSnackBar variant="success"
              message={this.state.mensagem} />}
          {this.state.erro &&
            <CustomSnackBar variant="error"
              message={this.state.mensagem} />}
          {listaContas.length < 1 &&
            <div className="card">
              <div className="card-content">
                <div className="card-title">
                  <h2>Cadastre sua Conta bancária abaixo</h2>
                  <p style={{ marginBottom: 20 }}>Você ainda <strong>não</strong> possui conta bancária cadastrada. Você só pode cadastrar <strong>uma conta</strong> bancária e preferencialmente
                                    <strong> Banco Do Brasil e Banco Itaú</strong>. Essa conta é a que você irá <strong>receber seus ganhos</strong> por seu trabalho no PlanoB
                                </p>
                </div>
                {!this.state.foi ? <button onClick={this.handleNovaConta} className="button is-info">Cadastrar Nova Conta</button> :
                  <button onClick={this.handleCancelarEdicao} className="button is-danger">Cancelar Cadastro</button>}
                {this.state.foi &&
                  <div className="columns is-multiline" style={{ marginTop: 30 }}>
                    <div className="column">
                      <div className="field">
                        <label className="label">Tipo de Conta:</label>
                        <div className="control">
                          <div className="select">
                            <select onChange={(e) => this.handleForm('tipoconta', e)}>
                              {listaTipoConta.map(tipoConta => (
                                <option key={tipoConta.id} value={tipoConta.id}>{tipoConta.descricao}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Banco:</label>
                        <div className="control">
                          <div className="select">
                            <select onChange={(e) => this.selecionarAgencia(e.target.value)}>
                              <option selected={true} value="" key="selecione" >Selecione seu Banco:</option>
                              {bancos.map(banco => (
                                <option key={banco.id} value={banco.id}>{banco.descricao}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Agência:</label>
                        <div className="control">
                          <div className="select">
                            <select
                              onChange={(e) => this.handleForm('agenciaid', e)}
                            >
                              <option value="0">Selecione sua Agência</option>
                              {this.state.listaAgencia.map((agencia, index) => (
                                <option key={index} value={agencia.id}>{agencia.codDescricao}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Conta:</label>
                        <div className="control">
                          <input type="text"
                            className="input is-rounded"
                            onChange={(e) => this.handleForm('numeroConta', e)}
                            disabled={this.state.btnDisabled} />
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Operação:</label>
                        <div className="control">
                          <input type="text"
                            placeholder="Opcional"
                            className="input is-rounded"
                            onChange={(e) => this.handleForm('operacao', e)}
                            disabled={this.state.btnDisabled} />
                        </div>
                      </div>
                    </div>
                    <div className="column alinamentoBotao">
                      <div className="field">
                        <button className="button is-success" onClick={this.novaContaCliente}>Cadastrar Banco</button>
                      </div>
                    </div>
                    <hr />
                  </div>}
              </div>
            </div>}
          <hr />
          <div className="card">
            <div className="card-content">
              <div>
                <h2>Conta Bancária</h2>
                {listaContas.length < 1 ? <h6 style={{ color: '#e74c3c' }}>Você não possui conta bancária cadastrada</h6> : ''}
              </div>
              <div className="columns is-multiline">
                {listaContas.map((conta, index) =>
                  <If test={index === 0}>
                    <div key={index} className="imovelItem column is-4" style={{ margin: 5 }}>
                      {this.state.edit !== index ?
                        <div className="" style={{ padding: 10 }}>
                          <p><strong>Tipo de Conta:</strong> {conta.tipo.descricao}</p>
                          <p><strong>Operação:</strong> {conta.operacao}</p>
                          <p><strong>Banco:</strong> {conta.banco.descricao}</p>
                          <p><strong>Agência:</strong> {conta.agencia.descricao}</p>
                          <p><strong>Número da Conta:</strong> {conta.numero}</p>
                          <div className="buttons has-addons">
                            <button onClick={() => this.handleEditarConta(index)} className="button is-info">Editar</button>
                            <button className="button is-danger" disabled>Excluir</button>
                          </div>
                        </div> :
                        <div className="imovelItem" style={{ padding: 10 }}>
                          <div className="marginEditAccount">
                            <label htmlFor="tipoConta"><strong className="alinhamentoEditLabel">Tipo de Conta:</strong> </label>
                            <div className="select">
                              <select onChange={(e) => this.handleForm('tipoconta', e)}>
                                {listaTipoConta.map(tipoConta => (
                                  <option key={tipoConta.id} value={tipoConta.id} selected={parseInt(this.state.tipoconta) === tipoConta.id ? true : false}>{tipoConta.descricao}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="marginEditAccount">
                            <label htmlFor="tipoConta"><strong className="alinhamentoEditLabel">Operação:</strong> </label>
                            <input type="text" defaultValue={this.state.operacao}
                              onChange={(e) => this.handleForm('operacao', e)}
                              className="input is-rounded" style={{ width: 300 }} />
                          </div>
                          <div className="marginEditAccount">
                            <label htmlFor="tipoConta"><strong className="alinhamentoEditLabel">Banco:</strong> </label>
                            <div className="select">
                              <select onChange={(e) => this.selecionarAgencia(e.target.value)}>
                                <option key='primeiro' selected={true} value="">Selecione seu Banco</option>
                                {bancos.map(banco => (
                                  <option key={banco.id} value={banco.id}>{banco.descricao}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="marginEditAccount">
                            <label htmlFor="tipoConta"><strong className="alinhamentoEditLabel">Agência:</strong> </label>
                            <div className="select">
                              <select onClick={(e) => this.handleForm('agenciaid', e)}>
                                {this.state.listaAgencia.map((agencia, index) => (
                                  <option key={index} value={agencia.id} selected={conta.agencia.id === agencia.id ? true : false}>{agencia.codDescricao}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="marginEditAccount">
                            <label htmlFor="tipoConta"><strong className="alinhamentoEditLabel">Número da Conta:</strong> </label>
                            <input type="text" defaultValue={this.state.numeroConta}
                              onChange={(e) => this.handleForm('numeroConta', e)}
                              className="input is-rounded" style={{ width: 300 }} />
                          </div>
                          <div className="buttons has-addons">
                            <button onClick={this.editarConta} className="button is-success">Confirmar</button>
                            <button onClick={this.handleCancelarEdicao} className="button is-danger">Cancelar</button>
                          </div>
                        </div>}
                    </div>
                  </If>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DadosBancarios